import { api as axios } from "services";
import { ExternalUrl, InternalUrl, Messages, ServerStatus } from "consts";
import { history } from "common/config";

import { formatDatetimeToBackend } from "helpers/dateTimeHelper";
import { convertCurrencyToBackendFormat } from "helpers/currencyHelper";

export default class TripActions {
  static GET_TRIPS = "TripActions.GET_TRIPS";
  static GET_TRIPS_REQUEST = `${TripActions.GET_TRIPS}_REQUEST`;
  static GET_TRIPS_SUCCESS = `${TripActions.GET_TRIPS}_SUCCESS`;
  static GET_TRIPS_FAILURE = `${TripActions.GET_TRIPS}_FAILURE`;
  static GET_TRIPS_CLEAR_FAILURE = `${TripActions.GET_TRIPS}_CLEARFAILURE`;

  static SAVE_TRIP = "TripActions.SAVE_TRIP";
  static SAVE_TRIP_REQUEST = `${TripActions.SAVE_TRIP}_REQUEST`;
  static SAVE_TRIP_SUCCESS = `${TripActions.SAVE_TRIP}_SUCCESS`;
  static SAVE_TRIP_FAILURE = `${TripActions.SAVE_TRIP}_FAILURE`;
  static SAVE_TRIP_CLEAR_FAILURE = `${TripActions.SAVE_TRIP}_CLEARFAILURE`;

  static CLEAR_TRIP_REQUEST = "TripActions.CLEAR_TRIP_REQUEST";

  static requestTripsPagination(page, pageSize, showPast) {
    return async dispatch => {
      dispatch({ type: TripActions.GET_TRIPS_REQUEST });

      axios
        .get(ExternalUrl.FETCH_TRIPS_COMPANY(page, pageSize, showPast))
        .then(data => {
          if (data.status === ServerStatus.SUCCESS) {
            dispatch({
              type: TripActions.GET_TRIPS_SUCCESS,
              payload: data
            });
          } else {
            dispatch({
              type: TripActions.GET_TRIPS_FAILURE,
              payload: data.data
            });
          }
        })
        .catch(error => {
          dispatch({
            type: TripActions.GET_TRIPS_FAILURE,
            payload: Messages.ERROR_UNEXPECTED_FROM_SERVER
          });
        });
    };
  }

  static saveTrip(formValues) {
    formValues.price = convertCurrencyToBackendFormat(formValues.price);

    formValues.departureTime = formatDatetimeToBackend(
      formValues.departureTime
    );

    formValues.arrivalTime = formatDatetimeToBackend(formValues.arrivalTime);

    return async dispatch => {
      dispatch({ type: TripActions.SAVE_TRIP_REQUEST });

      await axios
        .post(ExternalUrl.TRIPS, {
          ...formValues
        })
        .then(data => {
          if (data.status === ServerStatus.SUCCESS) {
            dispatch({
              type: TripActions.SAVE_TRIP_SUCCESS,
              payload: data
            });
            history.push(`${InternalUrl.TRIPS.LIST}`, {
              [ServerStatus.REQUEST_SUCCESS]: true
            });
          } else {
            dispatch({
              type: TripActions.SAVE_TRIP_FAILURE,
              payload: data.data
            });
          }
        })
        .catch(error => {
          dispatch({
            type: TripActions.SAVE_TRIP_FAILURE,
            payload: Messages.ERROR_UNEXPECTED_FROM_SERVER
          });
        });
    };
  }

  static clearTripRequest() {
    return dispatch => {
      dispatch({ type: TripActions.CLEAR_TRIP_REQUEST, payload: [] });
    };
  }

  static clearError(action) {
    return dispatch => {
      dispatch({ type: action });
    };
  }
}
