import VehicleActions from "./VehicleActions";

const INITIAL_STATE = {
  list: [],
  vehicle: {}
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case VehicleActions.GET_VEHICLES_SUCCESS:
      return { ...state, list: payload.data, vehicle: {} };

    case VehicleActions.GET_VEHICLES_FAILURE:
      return { ...state, list: [], vehicle: {} };

    case VehicleActions.GET_VEHICLE_SUCCESS:
      return { ...state, vehicle: payload };

    case VehicleActions.GET_VEHICLE_FAILURE:
      return { ...state, vehicle: {} };

    case VehicleActions.SAVE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: payload
      };

    case VehicleActions.EDIT_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicle: payload
      };

    case VehicleActions.SAVE_PHOTO_SUCCESS:
      return {
        ...state,
        vehicle: payload
      };

    default:
      return state;
  }
};
