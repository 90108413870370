import React, { Component } from "react";
import { connect } from "react-redux";
import NotificationActions from "common/redux/notifications/NotificationActions";
import { EventSourcePolyfill } from "event-source-polyfill";
import { ExternalUrl } from "consts";
import { history } from "common/config";
import { Router, Route, Switch } from "react-router-dom";
import PropTypes from "prop-types/prop-types";
import { getToken } from "services/auth";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute";
import { withStyles } from "@material-ui/core/styles";

import Admin from "layouts/Admin";
import SignIn from "views/SignIn/SignIn";
import UnknownError from "views/Error/UnknownError";

import "_assets/css/material-dashboard.css?v=1.8.0";

const NOTIFICATION_RETRY_TIMEOUT = 60000;

const styles = theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: 150
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    this.eventSource = null;
  }

  componentDidMount() {
    this.subscribeNotification();
  }

  subscribeNotification = () => {
    const token = getToken();

    if (token) {
      if (this.eventSource !== null) {
        return;
      }

      this.eventSource = new EventSourcePolyfill(
        ExternalUrl.NOTIFICATION_SUBSCRIBE,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      this.eventSource.onmessage = e => {
        const notification = JSON.parse(e.data);
        this.handleNotificationReceived(notification);
      };

      this.eventSource.onerror = e => {
        this.handleSubscriptionError();
      };
    }
  };

  unsubscribeNotification = () => {
    if (this.eventSource === null) return;

    this.eventSource.close();
    this.eventSource = null;
  };

  dispatchAction = (notifications, newNotification) => {
    const notificationFound = notifications.find(
      n => n.id === newNotification.id
    );
    if (!notificationFound) {
      this.props.dispatchActionNotification(notifications, newNotification);
    }
  };

  handleNotificationReceived = newNotification => {
    const { notifications } = this.props;

    if (newNotification.type === NotificationActions.CHAT) {
      this.dispatchAction(notifications.chat, newNotification);
    } else {
      // If notification isn't CHAT it just can will be NEW_RESERVATION or
      // CANCEL_RESERVATION because exists only three types of notifications
      this.dispatchAction(notifications.reservation, newNotification);
    }
  };

  handleSubscriptionError = () => {
    this.unsubscribeNotification();
    setTimeout(() => {
      this.subscribeNotification();
    }, NOTIFICATION_RETRY_TIMEOUT);
  };

  renderLoginPage = () => {
    return <SignIn subscribe={this.subscribeNotification} />;
  };

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={this.renderLoginPage} />
          <Route exact path="/unknown-error" component={UnknownError} />
          <PrivateRoute path="/admin" component={Admin} />
          <Route path="*" component={() => <h1>Page not found</h1>} />
        </Switch>
      </Router>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatchActionNotification: (notifications, newNotification) => {
    dispatch({
      type: newNotification.type,
      payload: [...notifications, newNotification]
    });
  }
});

const mapStateToProps = state => ({
  notifications: state.notifications
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(App));

App.propTypes = {
  classes: PropTypes.object,
  dispatchActionNotification: PropTypes.func,
  notifications: PropTypes.object
};
