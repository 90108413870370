import axios from "axios";
import { BACKEND_BASE_URL, InternalUrl } from "consts";
import { getToken } from "services";
import { history } from "common/config";

export const api = axios.create({
  baseURL: BACKEND_BASE_URL
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
});

api.interceptors.response.use(
  function(response) {
    return response.data;
  },
  function(error) {
    if (error.response) {
      switch (error.response.status) {
        case 403:
          history.push(InternalUrl.SIGN_IN);
          break;
        case 405:
          console.log("Error 405");
          break;
        case 500:
          console.log("Error 500");
          break;
        case 503:
          console.log("Error 503");
          break;
        default:
          history.push(InternalUrl.UNKNOWN_ERROR);
          break;
      }
    } else {
      console.log("Unavailable service");
    }
    return Promise.reject(error);
  }
);
