import React, { Component } from "react";
import { getParamsFromUrl } from "helpers/routeHelper";
import ButtonLoading from "components/CustomButtons/ButtonLoading";

import PropTypes from "prop-types/prop-types";

import { withRouter } from "react-router";
import RequestStatus, { Status } from "../../model/RequestStatus";
import {
  fetchVehiclesForCompany,
  getTripDetails,
  updateTripVehicle
} from "../../promises/promises";
import { ErrorOutline } from "@material-ui/icons";
import Snackbar from "../../components/Snackbar/Snackbar";
import SimpleBackdrop from "../../components/backdrop/Backdrop";
import _ from "lodash";

class ChangeVehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departureDate: undefined,
      trip: undefined,
      tripDetailsRequestStatus: new RequestStatus(Status.NOT_STARTED),
      changeVehicleRequestStatus: new RequestStatus(Status.NOT_STARTED),
      vehicleList: [],
      currentIndexOnList: undefined,
      snackbar: {
        open: false,
        color: null,
        message: ""
      }
    };
  }

  componentDidMount() {
    this.fetchInitialData();
  }

  fetchInitialData = () => {
    this.setState({
      tripDetailsRequestStatus: new RequestStatus(Status.LOADING)
    });

    const tripId = getParamsFromUrl(this.props, "id");

    getTripDetails(tripId)
      .then(tripDetailsResponse => {
        if (tripDetailsResponse.status !== "success") {
          throw new Error(
            `Não foi possível carregar os detalhes da viagem: ${tripDetailsResponse.data}`
          );
        }

        fetchVehiclesForCompany(tripDetailsResponse.data.companyId)
          .then(vehiclesResponse => {
            if (vehiclesResponse.status !== "success") {
              throw new Error(
                `Não foi possível carregar os detalhes dos veículos para troca: ${vehiclesResponse.data}`
              );
            }

            const vehicleList = vehiclesResponse.data || [];

            let distinctVehicleList = _.uniqBy(vehicleList, v => v.seats);
            distinctVehicleList = _.sortBy(distinctVehicleList, v => v.seats);

            const currentVehicleCapacity =
              tripDetailsResponse.data.vehicleCapacity;
            const currentIndexOnList = distinctVehicleList.findIndex(
              vehicle => vehicle.seats === currentVehicleCapacity
            );

            if (distinctVehicleList.length === 0) {
              throw new Error(`Nenhum veículo encontrado para a troca`);
            } else {
              this.setState({
                tripDetailsRequestStatus: new RequestStatus(Status.SUCCESS),
                trip: tripDetailsResponse.data,
                vehicleList: distinctVehicleList,
                currentIndexOnList: currentIndexOnList
              });
            }
          })
          .catch(error => {
            this.setSnackbar("danger", error.message, true);
          });
      })
      .catch(error => {
        this.setSnackbar("danger", error.message, true);
      });
  };

  changeVehicle = () => {
    this.setState({
      changeVehicleRequestStatus: new RequestStatus(Status.LOADING)
    });

    const {
      trip: { id: tripId },
      vehicleList,
      currentIndexOnList
    } = this.state;

    updateTripVehicle(tripId, vehicleList[currentIndexOnList].id)
      .then(response => {
        if (response.status !== "success") {
          const errorMessage = Array.isArray(response.data)
            ? response.data.join("; ")
            : response.data;
          throw new Error(errorMessage);
        }

        this.setState({
          changeVehicleRequestStatus: new RequestStatus(Status.SUCCESS),
          snackbar: {
            open: true,
            color: "success",
            message: "Veículo alterado com sucesso"
          }
        });

        setTimeout(this.closeSnackbar, 5000);

        this.fetchInitialData();
      })
      .catch(error => {
        this.setState({
          changeVehicleRequestStatus: new RequestStatus(
            Status.ERROR,
            error.message
          ),
          snackbar: {
            open: true,
            color: "danger",
            message: `Erro ao trocar veículo: ${error.message}`
          }
        });
      });
  };

  setSnackbar(color, message, open) {
    this.setState({
      snackbar: {
        open: open,
        color: color,
        message: message
      }
    });
  }

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        color: null,
        message: ""
      }
    });
  };

  previousVehicle = () => {
    const { currentIndexOnList } = this.state;

    this.setState({
      currentIndexOnList: currentIndexOnList - 1
    });
  };

  nextVehicle = () => {
    const { currentIndexOnList } = this.state;

    this.setState({
      currentIndexOnList: currentIndexOnList + 1
    });
  };

  render() {
    const {
      tripDetailsRequestStatus,
      changeVehicleRequestStatus,
      trip,
      desiredSeats,
      snackbar,
      vehicleList,
      currentIndexOnList
    } = this.state;

    const isAnyRequestInProgress =
      tripDetailsRequestStatus.status === Status.LOADING ||
      changeVehicleRequestStatus.status === Status.LOADING;

    return (
      <React.Fragment>
        <Snackbar
          place="tc"
          color={snackbar.color}
          icon={ErrorOutline}
          message={snackbar.message}
          open={snackbar.open}
          closeNotification={this.closeSnackbar}
          close
        />

        <SimpleBackdrop open={isAnyRequestInProgress} />

        <div style={{ maxWidth: "320px", margin: "auto" }}>
          {(() => {
            if (tripDetailsRequestStatus.status === Status.SUCCESS) {
              return (
                <div>
                  <p>
                    O veículo dessa viagem comporta{" "}
                    <strong>{trip.vehicleCapacity}</strong> assentos
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center"
                    }}
                  >
                    <ButtonLoading
                      onClick={this.previousVehicle}
                      disabled={currentIndexOnList === 0}
                      variant="contained"
                    >
                      -
                    </ButtonLoading>
                    <h1 style={{ paddingTop: "8px" }}>
                      {vehicleList[currentIndexOnList].seats}
                    </h1>
                    <ButtonLoading
                      onClick={this.nextVehicle}
                      disabled={currentIndexOnList === vehicleList.length - 1}
                      variant="contained"
                    >
                      +
                    </ButtonLoading>
                  </div>
                  <ButtonLoading
                    onClick={this.changeVehicle}
                    disabled={
                      trip.vehicleCapacity ===
                      vehicleList[currentIndexOnList].seats
                    }
                    variant="contained"
                    style={{
                      width: "100%",
                      display: "flex"
                    }}
                  >
                    Salvar
                  </ButtonLoading>
                </div>
              );
            }
          })()}
        </div>
      </React.Fragment>
    );
  }
}

ChangeVehicle.propTypes = {
  classes: PropTypes.object,
  clearForm: PropTypes.func,
  handleSubmit: PropTypes.func,
  tripFound: PropTypes.shape({
    informallyReservedSeats: PropTypes.string
  }),
  trips: PropTypes.array
};

export default withRouter(ChangeVehicle);
