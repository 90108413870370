import React, { Component } from "react";
import { connect } from "react-redux";
import AlertDialog from "components/AlertDialog/AlertDialog";
import VehicleActions from "views/Vehicle/redux/VehicleActions";
import PropTypes from "prop-types/prop-types";
import { history } from "common/config";
import { InternalUrl, Messages } from "consts";
import _ from "lodash";
import SimpleBackdrop from "components/backdrop/Backdrop";

class VehicleRemove extends Component {
  componentDidMount() {
    this.props.requestVehicle(this.props.match.params.id);
  }

  handleDialogCancel = () => {
    history.push(InternalUrl.VEHICLES.LIST);
  };

  handleDialogConfirm = () => {
    this.props.removeVehicle(this.props.vehicle.data.id);
  };

  render() {
    if (_.isEmpty(this.props.vehicle)) {
      return <SimpleBackdrop open={true} />;
    } else {
      return (
        <AlertDialog
          title={Messages.CONFIRM_REMOVE_VEHICLE_TITLE}
          content={Messages.CONFIRM_REMOVE_VEHICLE_CONTENT}
          handleDialogCancel={this.handleDialogCancel}
          handleDialogConfirm={this.handleDialogConfirm}
        />
      );
    }
  }
}

VehicleRemove.propTypes = {
  requestVehicle: PropTypes.func,
  removeVehicle: PropTypes.func,
  match: PropTypes.object,
  vehicle: PropTypes.object
};

const mapStateToProps = state => ({
  vehicle: state.vehicle.vehicle
});

export default connect(mapStateToProps, {
  requestVehicle: VehicleActions.requestVehicle,
  removeVehicle: VehicleActions.removeVehicle
})(VehicleRemove);
