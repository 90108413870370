import _ from "lodash";

export const createLoadingSelector = actions => state => {
  return _(actions).some(action => state.loading[action]);
};

export const createErrorMessageSelector = actions => state => {
  return (
    _(actions)
      .map(action => state.error[action])
      .compact()
      .first() || ""
  );
};
