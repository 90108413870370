import React, { Component } from "react";
import ButtonLoading from "components/CustomButtons/ButtonLoading";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EventSourcePolyfill } from "event-source-polyfill";
import { formatDatetime, datetimeToMoment } from "helpers/dateTimeHelper";
import { getToken } from "services/auth";
import { getMoreMessages, hasMessageBefore } from "promises/promises";
import { getParamsFromUrl } from "helpers/routeHelper";
import { grayColor } from "_assets/jss/materialAdmin";
import MessageInput from "views/Chat/components/MessageInput";
import PropTypes from "prop-types/prop-types";

import { withStyles } from "@material-ui/core/styles";

import {
  ExternalUrl,
  DATETIME_FORMAT,
  LAST_MESSAGE_CHAT_FORMAT,
  DATE_FORMAT
} from "consts";

import "./assets/chatBubble.css";

const subscribe = url => {
  return new EventSourcePolyfill(url, {
    headers: {
      Authorization: "Bearer " + getToken()
    }
  });
};

const useStyles = theme => ({
  startConversation: {
    color: grayColor[9],
    fontSize: 10,
    textAlign: "center"
  },
  loadingButton: {
    borderColor: grayColor[9],
    color: grayColor[9],
    fontSize: 8,
    width: 100
  },
  root: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(1),
      marginTop: theme.spacing(3)
    }
  }
});

class Messages extends Component {
  constructor(props) {
    super(props);
    this.eventSource = null;
    this.chatCode = getParamsFromUrl(this.props, "id");
    this.state = {
      hasMoreMessages: false,
      id: null,
      isLoadingMoreMessages: false,
      messages: []
    };
  }

  componentDidMount() {
    this.eventSource = subscribe(ExternalUrl.CHAT_SUBSCRIBE(this.chatCode));

    this.eventSource.onmessage = e => {
      const newMessage = JSON.parse(e.data);
      this.handleMessageReceived(newMessage);
    };
  }

  handleMessageReceived = newMessage => {
    const { messages } = this.state;

    const messageFound = messages.find(message => message.id === newMessage.id);

    if (messageFound) {
      Object.assign(messageFound, newMessage);
    } else {
      let positionToInsert = messages.length;
      for (let i = 0; i < messages.length; i++) {
        if (
          datetimeToMoment(messages[i].sentAt).isAfter(
            datetimeToMoment(newMessage.sentAt)
          )
        ) {
          positionToInsert = i;
          break;
        }
      }

      messages.splice(positionToInsert, 0, newMessage);

      if (positionToInsert === 0) {
        this.hasMoreMessageBefore(newMessage.id);
      }
    }

    this.setState(messages);
  };

  hasMoreMessageBefore = messageId => {
    hasMessageBefore(this.chatCode, messageId).then(response => {
      this.setState({ hasMoreMessages: response.data.data });
    });
  };

  fetchMoreMessages = () => {
    const { messages } = this.state;
    const oldestMessage = messages[0];

    this.setState({ isLoadingMoreMessages: true });

    getMoreMessages(this.chatCode, oldestMessage.id).then(response => {
      response.data.data.forEach(message => {
        this.handleMessageReceived(message);
        this.setState({ isLoadingMoreMessages: false });
      });
    });
  };

  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }

  getBubbleClass = fromCompany => {
    return fromCompany ? "me" : "you";
  };

  getHourClass = fromCompany => {
    return fromCompany ? "me-hour" : "you-hour";
  };

  showUsername = message => {
    return message.fromCompany ? null : `${message.name}, `;
  };

  render() {
    const { classes } = this.props;
    const { hasMoreMessages, isLoadingMoreMessages, messages } = this.state;

    let dataHourSameMinute = "";
    let dateSeparator = "";
    let isShowDateHour = false;
    let isShowDateSeparator = false;

    return (
      <div className={"container"}>
        <div>
          <MessageInput chatCode={this.chatCode} />
        </div>
        <div className={"holder-messages"}>
          <div>
            {!hasMoreMessages ? (
              <div className={classes.root}>
                <span className={classes.startConversation}>
                  Início da conversa
                </span>
                {messages.length === 0 ? (
                  <div>
                    <CircularProgress size={20} />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div>
              {hasMoreMessages ? (
                <div className={classes.root}>
                  <ButtonLoading
                    className={classes.loadingButton}
                    fullWidth
                    loading={isLoadingMoreMessages}
                    onClick={this.fetchMoreMessages}
                    variant="outlined"
                  >
                    carregar mais
                  </ButtonLoading>
                </div>
              ) : null}

              {messages.map((message, index) => {
                const { fromCompany, id, value } = message;
                const sentAtDate = formatDatetime(message.sentAt, DATE_FORMAT);
                const sentAtDateHour = formatDatetime(
                  message.sentAt,
                  DATETIME_FORMAT
                );

                if (index === 0 || dateSeparator !== sentAtDate) {
                  dateSeparator = sentAtDate;
                  isShowDateSeparator = true;
                } else {
                  isShowDateSeparator = false;
                }

                if (index === 0 || dataHourSameMinute !== sentAtDateHour) {
                  dataHourSameMinute = sentAtDateHour;
                  isShowDateHour = true;
                } else {
                  isShowDateHour = false;
                }

                return (
                  <div key={index}>
                    {isShowDateSeparator ? (
                      <div className="date-separator-line">
                        <span className="date-separator">{dateSeparator}</span>
                      </div>
                    ) : null}
                    <div key={id}>
                      <div className={"bubble-container"}>
                        {isShowDateHour ? (
                          <span className={this.getHourClass(fromCompany)}>
                            {this.showUsername(message)}
                            {formatDatetime(
                              message.sentAt,
                              LAST_MESSAGE_CHAT_FORMAT
                            )}
                          </span>
                        ) : null}
                        <div
                          className={
                            "bubble " + this.getBubbleClass(fromCompany)
                          }
                        >
                          {value}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Messages.propTypes = {
  classes: PropTypes.object,
  sentAt: PropTypes.string,
  fromCompany: PropTypes.bool
};

export default withStyles(useStyles)(Messages);
