enum Messages {
  CONFIRM_INFORMAL_RESERVATION_TITLE = "Alteração de reservas",
  CONFIRM_INFORMAL_RESERVATION_CONTENT = "Deseja realmente confirmar esta operação?",
  CONFIRM_TRIP_TITLE = "Confirmar viagem",
  CONFIRM_TRIP_CONTENT = "Esta operação não poderá ser desfeita, deseja realmente confirmar esta viagem?",
  CANCEL_TRIP_TITLE = "Cancelar viagem",
  CANCEL_TRIP_CONTENT = "Esta operação não poderá ser desfeita, deseja realmente cancelar esta viagem?",
  CONFIRM_REMOVE_VEHICLE_TITLE = "Confirmar exclusão",
  CONFIRM_REMOVE_VEHICLE_CONTENT = "Esta operação não poderá ser desfeita. Deseja confirmar esta operação?",
  ERROR_UNEXPECTED_FROM_SERVER = "Um erro inesperado ocorreu. Por favor, tente novamente mais tarde.",
  ERROR_REQUIRED_FIELDS = "Por favor, preencha os campos obrigatórios",
  ERROR_UNKNOWN = "Desculpe, ocorreu um erro desconhecido",
  ERROR_TRIP_DETAILS = "Não foi possível encontrar os detalhes da viagem.",
  EMPTY_TRIPS_LIST = "Não há viagens cadastradas",
  EMPTY_RESERVATION_LIST = "Desculpe, ainda não existem reservas concluídas!",
  EMPTY_VEHICLES_LIST = "Não há veículos cadastrados",
  EMPTY_CHATS_LIST = "Não há mensagens para serem exibidas",
  EMPTY_F2F_REQUEST = "Não há solicitações de pagamento presencial",
  SUCCESS_TRIP_SAVED = "Parabéns, nova viagem publicada com sucesso",
  SUCCESS_VEHICLE_SAVED = "Veículo salvo com sucesso",
  SUCCESS_VEHICLE_EDITED = "Veículo alterado com sucesso",
  SUCCESS_VEHICLE_REMOVED = "Veículo removido com sucesso",
  SUCCESS_INFORMAL_RESERVATION = "Alteração de reservas efetuada com sucesso",
  SUCCESS_CONFIRM_TRIP = "Viagem confirmada com sucesso",
  TRIP_SUCCESSFULY_CANCELLED = "Viagem cancelada com sucesso"
}

export default Messages;
