import React from "react";
import { maskHelper } from "helpers/maskHelper";
import MaskedInput from "react-text-mask";

function upperCasePipe(conformedValue) {
  return conformedValue.toUpperCase();
}

export default function InputMask(props) {
  // eslint-disable-next-line react/prop-types
  const { id, inputRef, mask, ...other } = props;

  return (
    <MaskedInput
      guide={false}
      mask={maskHelper[id]}
      pipe={upperCasePipe}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
    />
  );
}
