import { api as axios } from "services";
import { ExternalUrl, InternalUrl, Messages, ServerStatus } from "consts";
import { history } from "common/config";

import FormData from "form-data";

export default class VehicleActions {
  static GET_VEHICLES = "VehicleActions.GET_VEHICLES";
  static GET_VEHICLES_REQUEST = `${VehicleActions.GET_VEHICLES}_REQUEST`;
  static GET_VEHICLES_SUCCESS = `${VehicleActions.GET_VEHICLES}_SUCCESS`;
  static GET_VEHICLES_FAILURE = `${VehicleActions.GET_VEHICLES}_FAILURE`;
  static GET_VEHICLES_CLEAR_FAILURE = `${VehicleActions.GET_VEHICLES}_CLEARFAILURE`;

  static GET_VEHICLE = "VehicleActions.GET_VEHICLE";
  static GET_VEHICLE_REQUEST = `${VehicleActions.GET_VEHICLE}_REQUEST`;
  static GET_VEHICLE_SUCCESS = `${VehicleActions.GET_VEHICLE}_SUCCESS`;
  static GET_VEHICLE_FAILURE = `${VehicleActions.GET_VEHICLE}_FAILURE`;

  static SAVE_VEHICLE = "VehicleActions.SAVE_VEHICLES";
  static SAVE_VEHICLE_REQUEST = `${VehicleActions.SAVE_VEHICLE}_REQUEST`;
  static SAVE_VEHICLE_SUCCESS = `${VehicleActions.SAVE_VEHICLE}_SUCCESS`;
  static SAVE_VEHICLE_FAILURE = `${VehicleActions.SAVE_VEHICLE}_FAILURE`;
  static SAVE_VEHICLE_CLEAR_FAILURE = `${VehicleActions.SAVE_VEHICLE}_CLEARFAILURE`;

  static EDIT_VEHICLE = "VehicleActions.EDIT_VEHICLES";
  static EDIT_VEHICLE_REQUEST = `${VehicleActions.EDIT_VEHICLE}_REQUEST`;
  static EDIT_VEHICLE_SUCCESS = `${VehicleActions.EDIT_VEHICLE}_SUCCESS`;
  static EDIT_VEHICLE_FAILURE = `${VehicleActions.EDIT_VEHICLE}_FAILURE`;
  static EDIT_VEHICLE_CLEAR_FAILURE = `${VehicleActions.EDIT_VEHICLE}_CLEARFAILURE`;

  static REMOVE_VEHICLE = "VehicleActions.REMOVE_VEHICLES";
  static REMOVE_VEHICLE_REQUEST = `${VehicleActions.REMOVE_VEHICLE}_REQUEST`;
  static REMOVE_VEHICLE_SUCCESS = `${VehicleActions.REMOVE_VEHICLE}_SUCCESS`;
  static REMOVE_VEHICLE_FAILURE = `${VehicleActions.REMOVE_VEHICLE}_FAILURE`;
  static REMOVE_VEHICLE_CLEAR_FAILURE = `${VehicleActions.REMOVE_VEHICLE}_CLEARFAILURE`;

  static SAVE_PHOTO = "VehicleActions.SAVE_PHOTO";
  static SAVE_PHOTO_REQUEST = `${VehicleActions.SAVE_PHOTO}_REQUEST`;
  static SAVE_PHOTO_SUCCESS = `${VehicleActions.SAVE_PHOTO}_SUCCESS`;
  static SAVE_PHOTO_FAILURE = `${VehicleActions.SAVE_PHOTO}_FAILURE`;
  static SAVE_PHOTO_CLEAR_FAILURE = `${VehicleActions.SAVE_PHOTO}_CLEARFAILURE`;

  static requestVehicles() {
    return async dispatch => {
      dispatch({ type: VehicleActions.GET_VEHICLES_REQUEST });

      await axios
        .get(ExternalUrl.VEHICLES)
        .then(data => {
          if (data.status === ServerStatus.SUCCESS) {
            dispatch({
              type: VehicleActions.GET_VEHICLES_SUCCESS,
              payload: data
            });
          } else {
            dispatch({
              type: VehicleActions.GET_VEHICLES_FAILURE,
              payload: data.data
            });
          }
        })
        .catch(error => {
          dispatch({
            type: VehicleActions.GET_VEHICLES_FAILURE,
            payload: Messages.ERROR_UNEXPECTED_FROM_SERVER
          });
        });
    };
  }

  static requestVehicle(id) {
    return async dispatch => {
      dispatch({ type: VehicleActions.GET_VEHICLE_REQUEST });

      await axios
        .get(`${ExternalUrl.VEHICLES}/${id}`)
        .then(data => {
          if (data.status === ServerStatus.SUCCESS) {
            dispatch({
              type: VehicleActions.GET_VEHICLE_SUCCESS,
              payload: data
            });
          } else {
            dispatch({
              type: VehicleActions.GET_VEHICLE_FAILURE,
              payload: data.data
            });
          }
        })
        .catch(error => {
          dispatch({
            type: VehicleActions.GET_VEHICLE_FAILURE,
            payload: Messages.ERROR_UNEXPECTED_FROM_SERVER
          });
        });
    };
  }

  static saveVehicle(formValues) {
    return async dispatch => {
      dispatch({ type: VehicleActions.SAVE_VEHICLE_REQUEST });

      await axios
        .post(ExternalUrl.VEHICLES, {
          ...formValues
        })
        .then(data => {
          if (data.status === ServerStatus.SUCCESS) {
            dispatch({
              type: VehicleActions.SAVE_VEHICLE_SUCCESS,
              payload: data
            });
            history.push(`${InternalUrl.VEHICLES.EDIT}/${data.data.id}`, {
              [ServerStatus.REQUEST_SUCCESS]: VehicleActions.SAVE_VEHICLE
            });
          } else {
            dispatch({
              type: VehicleActions.SAVE_VEHICLE_FAILURE,
              payload: data.data
            });
          }
        })
        .catch(error => {
          dispatch({
            type: VehicleActions.SAVE_VEHICLE_FAILURE,
            payload: Messages.ERROR_UNEXPECTED_FROM_SERVER
          });
        });
    };
  }

  static editVehicle(formValues) {
    return async dispatch => {
      dispatch({ type: VehicleActions.EDIT_VEHICLE_REQUEST });

      await axios
        .patch(ExternalUrl.VEHICLES, {
          ...formValues
        })
        .then(data => {
          if (data.status === ServerStatus.SUCCESS) {
            dispatch({
              type: VehicleActions.EDIT_VEHICLE_SUCCESS,
              payload: data
            });
            history.push(InternalUrl.VEHICLES.LIST, {
              [ServerStatus.REQUEST_SUCCESS]: VehicleActions.EDIT_VEHICLE
            });
          } else {
            dispatch({
              type: VehicleActions.EDIT_VEHICLE_FAILURE,
              payload: data.data
            });
          }
        })
        .catch(error => {
          dispatch({
            type: VehicleActions.EDIT_VEHICLE_FAILURE,
            payload: Messages.ERROR_UNEXPECTED_FROM_SERVER
          });
        });
    };
  }

  static removeVehicle(id) {
    return async dispatch => {
      dispatch({ type: VehicleActions.REMOVE_VEHICLE_REQUEST });

      await axios
        .delete(ExternalUrl.VEHICLES, {
          data: { id: id }
        })
        .then(data => {
          if (data.status === ServerStatus.SUCCESS) {
            dispatch({
              type: VehicleActions.REMOVE_VEHICLE_SUCCESS,
              payload: data
            });
            history.push(InternalUrl.VEHICLES.LIST, {
              [ServerStatus.REQUEST_SUCCESS]: VehicleActions.REMOVE_VEHICLE
            });
          } else {
            dispatch({
              type: VehicleActions.REMOVE_VEHICLE_FAILURE,
              payload: data.data
            });
            history.push(InternalUrl.VEHICLES.LIST);
          }
        })
        .catch(error => {
          dispatch({
            type: VehicleActions.REMOVE_VEHICLE_FAILURE,
            payload: Messages.ERROR_UNEXPECTED_FROM_SERVER
          });
          history.push(InternalUrl.VEHICLES.LIST);
        });
    };
  }

  static savePhoto(file, id) {
    const formData = new FormData();
    formData.append("photo", file);

    return async dispatch => {
      dispatch({ type: VehicleActions.SAVE_PHOTO_REQUEST });

      await axios
        .post(`${ExternalUrl.VEHICLES}/${id}`, formData, {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
          }
        })
        .then(data => {
          if (data.status === ServerStatus.SUCCESS) {
            dispatch({
              type: VehicleActions.SAVE_PHOTO_SUCCESS,
              payload: data
            });
          } else {
            dispatch({
              type: VehicleActions.SAVE_PHOTO_FAILURE,
              payload: data.data
            });
          }
        })
        .catch(error => {
          dispatch({
            type: VehicleActions.SAVE_PHOTO_FAILURE,
            payload: Messages.ERROR_UNEXPECTED_FROM_SERVER
          });
        });
    };
  }

  static clearError(action) {
    return dispatch => {
      dispatch({ type: action });
    };
  }
}
