import React, { useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFDownloadLink
} from "@react-pdf/renderer";
import PropTypes from "prop-types/prop-types";
import logo from "_assets/img/logo-unnamed.png";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { phoneMask, cpfMask } from "../helpers/maskHelper";
import "moment/locale/pt-br";
import moment from "moment";
import ServerStatus from "consts/ServerStatus";
import { getReservationAllowanceDeadline } from "../promises/promises";

const styles = StyleSheet.create({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignSelf: "center"
  },
  logo: {
    width: 80,
    height: 80,
    margin: 5
  },
  heading1: {
    fontSize: 18,
    fontWeight: "bold",
    padding: 5,
    marginBottom: 5
  },
  heading2: {
    fontSize: 14,
    fontWeight: "bold",
    padding: 3
  },
  content: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10
  },
  tableHeader: {
    fontSize: 11,
    border: 0.6,
    textAlign: "center",
    backgroundColor: "#281ee5",
    paddingTop: 5,
    color: "white",
    height: 20
  },
  tableRow: {
    paddingTop: 5,
    paddingLeft: 5,
    border: 0.6,
    fontSize: 11,
    height: 20
  },
  documentDate: {
    fontSize: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 20
  },
  footerDetailsRadOver: {
    fontSize: 10,
    backgroundColor: "#50c105",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "center",
    marginTop: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 100,
    width: 290
  },
  footerDetails: {
    fontSize: 10,
    backgroundColor: "#e32828",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "center",
    marginTop: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 100,
    width: 300
  }
});

export default function PassengersListPDF(props) {
  const { trip, reservations, onCallback, isLightList } = props;
  const [rad, setRad] = useState("");
  useEffect(() => {
    if (trip.id) {
      getReservationAllowanceDeadline(trip.id).then(data => {
        if (data.status.toString() === ServerStatus.SUCCESS) {
          setRad(data.data);
        }
      });
    }
  }, [rad, trip]);

  function getRadAsDate() {
    return moment(rad, "DD/MM/YY HH:mm");
  }

  function isRadOver() {
    return getRadAsDate().isBefore(moment(moment.now()));
  }

  const tableRow = fieldName => {
    const passengers = [];
    const rows = [];

    if (!Array.isArray(reservations)) {
      // eslint-disable-next-line array-callback-return
      reservations.reservations.map(reservation => {
        if (reservation.status === "CONFIRMED") {
          reservation.passengers.map(passenger => {
            if (reservation.boardingPoint) {
              passenger.departureLocation =
                reservation.boardingPoint.displayName;
            }

            if (
              reservation.paymentMethod &&
              reservation.paymentMethod === "PIX"
            ) {
              passenger.paymentMethod = "Pago com Pix";
            } else {
              passenger.paymentMethod = "Pagar no embarque";
            }

            passengers.push(passenger);
          });
        }
      });
      reservations.informalReservations.map(informalPassenger => {
        informalPassenger.paymentMethod = "Pagar no embarque";
        passengers.push(informalPassenger);
        return null;
      });
    }

    // eslint-disable-next-line array-callback-return
    passengers.map((passenger, i) => {
      rows.push(
        <Text key={i + "_pax"} style={styles.tableRow}>
          {fieldName === "cpf" 
            ? cpfMask(passenger[fieldName]) 
            : fieldName === "phone" && !passenger.isInformalReservation
                ? phoneMask(passenger[fieldName])
                : passenger[fieldName]}
        </Text>
      );
    });

    Array(trip.vehicleCapacity - passengers.length)
      .fill(1)
      // eslint-disable-next-line array-callback-return
      .map((v, i) => {
        rows.push(
          <Text key={i} style={styles.tableRow}>
            {null}
          </Text>
        );
      });

    return rows;
  };

  const createPDFName = isTitle => {
    const { departure, arrival, departureTime } = trip;

    if (departure && arrival && departureTime) {
      return (
        departure.substr(0, departure.length - 4) +
        " x " +
        arrival.substr(0, arrival.length - 4) +
        " - " +
        (isTitle
          ? departureTime.replaceAll("-", "/")
          : departureTime.replace(":", "h") + ".pdf")
      );
    }
  };

  return (
    <PDFDownloadLink
      document={
        <Document>
          <Page size="A4" orientation="landscape">
            <View style={styles.header}>
              {!isLightList && (
                <View>
                  <Image src={logo} style={styles.logo} />
                </View>
              )}
              <View>
                <Text style={styles.heading1}>Lista de passageiros</Text>
                <Text style={styles.heading2}>{createPDFName(true)}</Text>
              </View>
            </View>
            <View style={styles.content}>
              <View style={{ width: 250 }}>
                <Text style={styles.tableHeader}>Nome</Text>
                {tableRow("name")}
              </View>
              {!isLightList && (
                <View style={{ width: 180 }}>
                  <Text style={styles.tableHeader}>Embarque</Text>
                  {tableRow("departureLocation")}
                </View>
              )}
              {!isLightList && (
                <View style={{ width: 95 }}>
                  <Text style={styles.tableHeader}>Telefone</Text>
                  {tableRow("phone")}
                </View>
              )}
              {!isLightList && (
                <View style={{ width: 110 }}>
                  <Text style={styles.tableHeader}>Pagamento</Text>
                  {tableRow("paymentMethod")}
                </View>
              )}
              {isLightList && (
                <View style={{ width: 110 }}>
                  <Text style={styles.tableHeader}>Documento</Text>
                  {tableRow("cpf")}
                </View>
              )}
            </View>
            <View
              style={
                isRadOver() ? styles.footerDetailsRadOver : styles.footerDetails
              }
            >
              <Text>
                {isRadOver()
                  ? getRadAsDate().format(
                      isLightList 
                        ? "[Lista final]" 
                        : "[Lista final com reservas efetuadas até] DD/MM/YYYY [às] HH:mm"
                    )
                  : getRadAsDate().format(
                      "[Esta lista está sujeita a alterações até] DD/MM/YYYY [às] HH:mm"
                    )}
              </Text>
            </View>
            <View style={styles.documentDate}>
              <Text>
                {moment(moment.now()).format(
                  "[Documento gerado em] DD/MM/YYYY [às] HH:mm"
                )}
              </Text>
            </View>
          </Page>
        </Document>
      }
      fileName={createPDFName(false)}
    >
      {({ blob, url, loading, error }) => {
        if (!loading) {
          setTimeout(() => {
            const div = document.getElementById("downloadPDFDiv");

            if (div) {
              div.children[0].click();
            }
            onCallback();
          }, 1500);
        }

        return (
          <IconButton
            aria-label="Lista de passageiros"
            style={{ marginTop: "4px", width: "50px", height: "20px" }}
          >
            <CircularProgress size={18} />
          </IconButton>
        );
      }}
    </PDFDownloadLink>
  );
}

PassengersListPDF.propTypes = {
  trip: PropTypes.object,
  reservations: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCallback: PropTypes.func,
  isLightList: PropTypes.bool
};
