import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { InternalUrl } from "consts";
import Typography from "@material-ui/core/Typography";
import logo from "_assets/img/logo.png";
import { Messages } from "consts";
import Button from "components/CustomButtons/Button";
import { removeToken } from "services";

export default function UnknownError() {
  useEffect(() => {
    removeToken();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          flexDirection: "column"
        }}
      >
        <div>
          <img
            src={logo}
            alt="logo"
            style={{ width: "130px", border: "0", margin: "10px 0 10px 0" }}
          />
        </div>

        <Typography
          variant="subtitle1"
          color="textPrimary"
          component="h1"
          align={"center"}
          style={{ fontWeight: "bold", padding: "80px 5px 15px 5px" }}
        >
          {Messages.ERROR_UNKNOWN}
        </Typography>

        <Link to={InternalUrl.SIGN_IN}>
          <Button color={"info"}>Voltar</Button>
        </Link>
      </div>
    </>
  );
}
