import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import ButtonLoading from "components/CustomButtons/ButtonLoading";
import DepartureBoardIcon from "@material-ui/icons/DepartureBoard";
import List from "@material-ui/icons/List";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Snackbar from "components/Snackbar/Snackbar";
import TripConditionForm from "views/Trip/components/TripConditionForm";
import TripDetailsForm from "views/Trip/components/TripDetailsForm";
import TripLocationForm from "views/Trip/components/TripLocationForm";
import TripActions from "views/Trip/redux/TripActions";

import PropTypes from "prop-types/prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { warningColor } from "_assets/jss/materialAdmin";
import {
  createErrorMessageSelector,
  createLoadingSelector
} from "common/redux/loading/selectors";
import { ErrorOutline } from "@material-ui/icons";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <CardBody
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {children}
    </CardBody>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  appBar: {
    // paddingTop: "10px",
    // padding: "20px 0",
    position: "static",
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    width: "100%",
    zIndex: "1029",
    border: "2px",
    borderRadius: "0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  }
});

class TabsTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      travelVehicleSelected: {},
      hiddenConditionForm: true,
      hiddenDetailsForm: true,
      snackbar: { message: "", open: false }
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { snackbar } = this.state;
    const { failureToSave } = this.props;

    if (failureToSave && !snackbar.open) {
      this.handleSnackbar(failureToSave, true);
      this.props.clearError(TripActions.SAVE_TRIP_CLEAR_FAILURE);
    }
  }

  handleSnackbar = (message, open) => {
    this.setState({ snackbar: { message, open } });
  };

  handleChangeTab = (event, newValue) => {
    this.setState({ value: newValue });
  };

  onSubmit = formValues => {
    this.setState({ alertOpen: false });
    const conditions = { ...formValues.conditions };
    formValues.conditions = [];
    Object.keys(conditions)
      .filter(i => conditions[i] === true)
      .map(id => {
        return formValues.conditions.push({ id: id });
      });
    this.props.saveTrip(formValues);
  };

  setTravelVehicle = vehicle => {
    this.setState({ travelVehicleSelected: vehicle });
  };

  showConditionForm = () => {
    this.setState({ hiddenConditionForm: false });
    this.setState({ value: 1 });
  };

  showLocationForm = () => {
    this.setState({ value: 0 });
  };

  showDetailsForm = () => {
    this.setState({ hiddenDetailsForm: false });
    this.setState({ value: 2 });
  };

  render() {
    const { classes } = this.props;
    const { open, message } = this.state.snackbar;

    return (
      <div className={classes.root}>
        <Snackbar
          place="tl"
          color="danger"
          icon={ErrorOutline}
          message={message}
          open={open}
          closeNotification={() => this.handleSnackbar("", false)}
          close
        />
        <AppBar className={classes.appBar}>
          <Tabs
            value={this.state.value}
            onChange={this.handleChangeTab}
            // variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
            centered={true}
          >
            <Tab
              disabled={false}
              label={window.innerWidth > 960 ? "Local e hora" : ""}
              icon={<DepartureBoardIcon />}
              {...a11yProps(0)}
            />
            <Tab
              disabled={this.state.hiddenConditionForm}
              label={window.innerWidth > 960 ? "Veículo" : ""}
              icon={<AirportShuttleIcon />}
              {...a11yProps(1)}
            />
            <Tab
              disabled={this.state.hiddenDetailsForm}
              label={window.innerWidth > 960 ? "Detalhes" : ""}
              icon={<List />}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <TabPanel value={this.state.value} index={0}>
                <TripLocationForm />
                <CardFooter>
                  <ButtonLoading
                    variant="contained"
                    onClick={this.showConditionForm}
                    style={{ width: "120px" }}
                  >
                    Próximo
                  </ButtonLoading>
                </CardFooter>
              </TabPanel>
              <TabPanel value={this.state.value} index={1}>
                <TripConditionForm setTravelVehicle={this.setTravelVehicle} />
                <CardFooter>
                  <ButtonLoading
                    variant="contained"
                    onClick={this.showLocationForm}
                    style={{
                      backgroundColor: warningColor[0],
                      width: "120px",
                      marginRight: 5
                    }}
                  >
                    Anterior
                  </ButtonLoading>
                  <ButtonLoading
                    variant="contained"
                    onClick={this.showDetailsForm}
                    style={{ width: "120px" }}
                  >
                    Próximo
                  </ButtonLoading>
                </CardFooter>
              </TabPanel>
              <TabPanel value={this.state.value} index={2}>
                <TripDetailsForm
                  travelVehicleSelected={this.state.travelVehicleSelected}
                />
                <CardFooter style={{ marginTop: 0, paddingTop: 0 }}>
                  {/*<Link to={InternalUrl.LIST_TRIP}>*/}
                  <ButtonLoading
                    variant="contained"
                    onClick={this.showConditionForm}
                    color={"secondary"}
                    style={{
                      width: "120px",
                      marginRight: 5
                    }}
                  >
                    Anterior
                  </ButtonLoading>
                  {/*</Link>*/}
                  <ButtonLoading
                    variant="contained"
                    type="submit"
                    loading={this.props.isFetching}
                    style={{ width: "120px" }}
                  >
                    Salvar
                  </ButtonLoading>
                </CardFooter>
              </TabPanel>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const loadingSelector = createLoadingSelector([TripActions.SAVE_TRIP]);
const errorSave = createErrorMessageSelector([TripActions.SAVE_TRIP]);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number
};

TabsTrip.propTypes = {
  classes: PropTypes.object,
  clearError: PropTypes.func,
  setVehicleId: PropTypes.func,
  handleSubmit: PropTypes.func,
  saveTrip: PropTypes.func,
  isFetching: PropTypes.bool,
  failureToSave: PropTypes.oneOf(["string", "array"])
};

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  failureToSave: errorSave(state)
});

export default reduxForm({
  form: "TripForm"
})(
  connect(mapStateToProps, {
    saveTrip: TripActions.saveTrip,
    clearError: TripActions.clearError
  })(withStyles(styles)(TabsTrip))
);
