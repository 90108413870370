export const maskHelper = {
  plateNumber: [
    /[a-zA-Z]/,
    /[a-zA-Z]/,
    /[a-zA-Z]/,
    " ",
    /\d/,
    /\.?/,
    /\d/,
    /\d/
  ],

  year: [/\d/, /\d/, /\d/, /\d/],

  seats: [/\d/, /\d/],

  minimumSeatsToDepart: [/\d/, /\d/],

  phone: [
    "(",
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ],

  reservedSeats: [/\d/, /\d/]
};

export const cpfMask = value => {
  if (value && value.length === 4) {
    return "***.***.*" + value.substring(0, 2) + "-" + value.substring(2);
  }
  if (!value) return "";
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};

export const phoneMask = value => {
  if (value && value.length === 4) {
    return "(**) *****-" + value;
  }
  if (!value) return "";
  const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
  const str = value.replace(/[^0-9]/g, "").slice(0, 11);
  return str.replace(regex, "($1) $2-$3");
};
