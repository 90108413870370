import React from "react";
import PropTypes from "prop-types/prop-types";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const defaultMaskOptions = {
  prefix: "R$ ",
  suffix: "",
  includeThousandsSeparator: false,
  decimalSymbol: ",",
  decimalLimit: 2,
  allowNegative: false,
  allowLeadingZeroes: false
};

const BrlCurrencyComponent = props => {
  const { inputRef, ...other } = props;

  const currencyMask = createNumberMask({
    ...defaultMaskOptions
  });

  return (
    <MaskedInput
      mask={currencyMask}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
    />
  );
};

BrlCurrencyComponent.defaultProps = {
  inputMode: "numeric",
  maskOptions: {}
};

BrlCurrencyComponent.propTypes = {
  inputmode: PropTypes.string,
  inputRef: PropTypes.node,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number
  })
};

export default BrlCurrencyComponent;

// import React from "react";
// import IntlCurrencyInput from "react-intl-currency-input";
//
// const currencyConfig = {
//   locale: "pt-BR",
//   formats: {
//     number: {
//       BRL: {
//         style: "currency",
//         currency: "BRL",
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2
//       }
//     }
//   }
// };
//
// const BrlCurrencyComponent = inputProps => {
//   return (
//     <IntlCurrencyInput currency="BRL" config={currencyConfig} {...inputProps} />
//   );
// };
//
// export default BrlCurrencyComponent;
