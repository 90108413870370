import React, { Component } from "react";
import { getParamsFromUrl } from "helpers/routeHelper";
import ButtonLoading from "components/CustomButtons/ButtonLoading";

import PropTypes from "prop-types/prop-types";

import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { withRouter } from "react-router";
import RequestStatus, { Status } from "../../model/RequestStatus";
import { doCloneTrip, getTripDetails } from "../../promises/promises";
import { ErrorOutline } from "@material-ui/icons";
import Snackbar from "../../components/Snackbar/Snackbar";
import SimpleBackdrop from "../../components/backdrop/Backdrop";
import { formatDatetime } from "../../helpers/dateTimeHelper";
import { InternalUrl } from "../../consts/urls";
import { Link } from "react-router-dom";

const DATE_FORMAT = "DD-MM-YYYY";
const TIME_FORMAT = "HH:mm";

class CloneTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      departureDate: undefined,
      trip: undefined,
      tripDetailsRequestStatus: new RequestStatus(Status.NOT_STARTED),
      cloneTripRequestStatus: new RequestStatus(Status.NOT_STARTED),
      clonedTripId: undefined,
      snackbar: {
        open: false,
        color: null,
        message: ""
      }
    };
  }

  componentDidMount() {
    this.getTripDetails();
  }

  getTripDetails = () => {
    this.setState({
      tripDetailsRequestStatus: new RequestStatus(Status.LOADING)
    });

    const tripId = getParamsFromUrl(this.props, "id");

    getTripDetails(tripId)
      .then(response => {
        if (response.status !== "success") {
          throw new Error(response.data);
        }

        this.setState({
          tripDetailsRequestStatus: new RequestStatus(Status.SUCCESS),
          trip: response.data
        });
      })
      .catch(error => {
        this.setState({
          tripDetailsRequestStatus: new RequestStatus(
            Status.ERROR,
            error.message
          ),
          snackbar: {
            open: true,
            color: "danger",
            message: "Erro ao obter os detalhes da viagem"
          }
        });
      });
  };

  cloneTrip = () => {
    this.setState({
      cloneTripRequestStatus: new RequestStatus(Status.LOADING)
    });

    const tripId = getParamsFromUrl(this.props, "id");

    const { departureDate } = this.state;

    const formattedDepartureDate = formatDatetime(departureDate, DATE_FORMAT);
    const formattedDepartureTime = formatDatetime(departureDate, TIME_FORMAT);

    const cloneTripObject = {
      departureDateTimes: [
        {
          departureDate: formattedDepartureDate,
          departureTimes: [formattedDepartureTime]
        }
      ]
    };

    doCloneTrip(tripId, cloneTripObject)
      .then(response => {
        if (response.status !== "success") {
          throw new Error(response.data.join("; "));
        }

        this.setState({
          cloneTripRequestStatus: new RequestStatus(Status.SUCCESS),
          clonedTripId: response.data[0],
          snackbar: {
            open: true,
            color: "success",
            message: "Viagem clonada com sucesso"
          }
        });

        setTimeout(this.closeSnackbar, 5000);
      })
      .catch(error => {
        this.setState({
          cloneTripRequestStatus: new RequestStatus(
            Status.ERROR,
            error.message
          ),
          snackbar: {
            open: true,
            color: "danger",
            message: `Erro ao clonar viagem: ${error.message}`
          }
        });
      });
  };

  onDepartureDateChange = date => {
    this.setState({
      departureDate: date
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        color: null,
        message: ""
      }
    });
  };

  render() {
    const {
      departureDate,
      tripDetailsRequestStatus,
      cloneTripRequestStatus,
      clonedTripId,
      snackbar
    } = this.state;

    const isAnyRequestInProgress =
      tripDetailsRequestStatus.status === Status.LOADING ||
      cloneTripRequestStatus.status === Status.LOADING;

    return (
      <React.Fragment>
        <Snackbar
          place="tc"
          color={snackbar.color}
          icon={ErrorOutline}
          message={snackbar.message}
          open={snackbar.open}
          closeNotification={this.closeSnackbar}
          close
        />

        <SimpleBackdrop open={isAnyRequestInProgress} />

        <div style={{ maxWidth: "320px", margin: "auto" }}>
          {(() => {
            if (cloneTripRequestStatus.status === Status.SUCCESS) {
              return (
                <div>
                  <h5>A viagem foi clonada com sucesso</h5>
                  <Link to={InternalUrl.MANAGE_TRIP(clonedTripId)}>
                    <ButtonLoading
                      disabled={!departureDate}
                      variant="contained"
                      style={{
                        width: "100%",
                        display: "flex"
                      }}
                    >
                      Ver viagem
                    </ButtonLoading>
                  </Link>
                </div>
              );
            }

            return (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
                  <DateTimePicker
                    disablePast
                    minDate={new Date()}
                    value={departureDate || null}
                    onChange={this.onDepartureDateChange}
                    label="Data e hora"
                    placeholder="Selecione a data e hora"
                    ampm={false}
                    cancelLabel={"Cancelar"}
                    format={"dd/MM/yyyy 'às' HH:mm"}
                    TextFieldComponent={this.renderCustomInput}
                    style={{ width: "100%" }}
                  />
                </MuiPickersUtilsProvider>
                <ButtonLoading
                  onClick={this.cloneTrip}
                  disabled={!departureDate}
                  variant="contained"
                  style={{
                    width: "100%",
                    display: "flex"
                  }}
                >
                  Confirmar
                </ButtonLoading>
              </>
            );
          })()}
        </div>
      </React.Fragment>
    );
  }
}

CloneTrip.propTypes = {
  classes: PropTypes.object,
  clearForm: PropTypes.func,
  handleSubmit: PropTypes.func,
  tripFound: PropTypes.shape({
    informallyReservedSeats: PropTypes.stringS
  }),
  trips: PropTypes.array
};

export default withRouter(CloneTrip);
