import React, { Component } from "react";
import _ from "lodash";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Messages } from "consts";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { formatDatetime } from "helpers/dateTimeHelper";
import { doCancelTrip, doConfirmTrip, doInformalReservation } from "promises";
import { getParamsFromUrl } from "helpers/routeHelper";
import { ErrorOutline } from "@material-ui/icons";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ButtonLoading from "components/CustomButtons/ButtonLoading";
import CardFooter from "components/Card/CardFooter";
import CustomInput from "components/CustomInput/CustomInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SimpleBackdrop from "components/backdrop/Backdrop";
import Snackbar from "components/Snackbar/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";

import AlertDialog from "components/AlertDialog/AlertDialog";
import ServerStatus from "consts/ServerStatus";
import { Field, reduxForm, reset } from "redux-form";
import PropTypes from "prop-types/prop-types";
import ErrorHelperText, {
  isInputError,
  isInputSuccess
} from "components/Error/ErrorHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import InputMask from "components/InputMask/InputMask";
import Can from "components/Rules/Can";

import {
  infoColor,
  grayColor,
  successColor,
  dangerColor
} from "_assets/jss/materialAdmin";
import { Link } from "react-router-dom";
import { InternalUrl } from "../../consts/urls";
import { getBoardingPoints, getTripDetails } from "../../promises/promises";

const useStyles = () => ({
  card: {
    maxWidth: 345,
    paddingLeft: 10,
    paddingRight: 10
  },
  formControl: {
    width: "100%",
    marginTop: "27px"
  },
  slider: {
    height: 60,
    maxWidth: 345,
    marginTop: "60px"
  }
});

class ManageTrip extends Component {
  constructor(props) {
    super(props);
    this.idTrip = getParamsFromUrl(this.props, "id");
    this.state = {
      trip: null,
      boardingPoints: null,
      isRequestInProgress: false,
      snackbar: {
        open: false,
        color: null,
        message: ""
      },
      alertController: {
        open: false,
        title: "",
        content: "",
        action: null
      }
    };
  }

  async componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({
      isRequestInProgress: true
    });

    Promise.all([getBoardingPoints(this.idTrip), getTripDetails(this.idTrip)])
      .then(([boardingPointResponse, tripDetailResponse]) => {
        if (boardingPointResponse.status !== "success") {
          throw new Error(boardingPointResponse.data);
        } else if (tripDetailResponse.status !== "success") {
          throw new Error(tripDetailResponse.data);
        }

        this.setState({
          boardingPoints: boardingPointResponse.data,
          trip: tripDetailResponse.data
        });
      })
      .catch(error => {
        this.setSnackbar(
          "error",
          `${Messages.ERROR_TRIP_DETAILS} ${error.message}`,
          true
        );
      })
      .finally(() => {
        this.setState({
          isRequestInProgress: false
        });
      });
  };

  confirmTrip = () => {
    this.setState({
      alertController: { open: false },
      isRequestInProgress: true
    });

    doConfirmTrip(this.state.trip.id)
      .then(data => {
        if (data.status === ServerStatus.SUCCESS) {
          this.setSnackbar("success", Messages.SUCCESS_CONFIRM_TRIP, true);
          this.setState({ trip: { ...this.state.trip, status: "CONFIRMED" } });
        } else {
          this.setSnackbar("danger", data.data, true);
        }
      })
      .catch(error => {
        this.setSnackbar("danger", Messages.ERROR_UNEXPECTED_FROM_SERVER, true);
      })
      .finally(() => {
        this.setState({ isRequestInProgress: false });

        setTimeout(() => {
          this.setSnackbar(this.state.snackbar.color, "", false);
        }, 5000);
      });
  };

  cancelTrip = () => {
    this.setState({
      alertController: { open: false },
      isRequestInProgress: true
    });

    doCancelTrip(this.state.trip.id)
      .then(data => {
        if (data.status === ServerStatus.SUCCESS) {
          this.setSnackbar(
            "success",
            Messages.TRIP_SUCCESSFULY_CANCELLED,
            true
          );
          this.setState({ trip: { ...this.state.trip, status: "CANCELLED" } });
        } else {
          this.setSnackbar("danger", data.data, true);
        }
      })
      .catch(error => {
        this.setSnackbar("danger", Messages.ERROR_UNEXPECTED_FROM_SERVER, true);
      })
      .finally(() => {
        this.setState({ isRequestInProgress: false });

        setTimeout(() => {
          this.setSnackbar(this.state.snackbar.color, "", false);
        }, 5000);
      });
  };

  informalReservation = formValues => {
    this.setState({ open: false, isRequestInProgress: true });

    doInformalReservation(formValues, this.state.trip.id)
      .then(data => {
        if (data.status === ServerStatus.SUCCESS) {
          this.setSnackbar(
            "success",
            Messages.SUCCESS_INFORMAL_RESERVATION,
            true
          );
          this.props.clearForm();
        } else {
          this.setSnackbar("danger", data.data, true);
        }
      })
      .catch(error => {
        this.setSnackbar("danger", Messages.ERROR_UNEXPECTED_FROM_SERVER, true);
      })
      .finally(() => {
        this.setState({ isRequestInProgress: false });

        setTimeout(() => {
          this.setSnackbar(this.state.snackbar.color, "", false);
        }, 5000);
      });
  };

  setSnackbar(color, message, open) {
    this.setState({
      snackbar: {
        open: open,
        color: color,
        message: message
      }
    });
  }

  onClick(title, content, fallback) {
    this.setState({
      alertController: {
        open: true,
        title: title,
        content: content,
        action: fallback
      }
    });
  }

  renderDepartureSelect = ({ input, meta }) => {
    const { boardingPoints } = this.state;
    return (
      <FormControl className={this.props.classes.formControl}>
        <InputLabel id="departureLocation">Local de embarque</InputLabel>
        <Select {...input} id="departureLocation-select" value={input.value}>
          {boardingPoints.map(boardingPoint => (
            <MenuItem value={boardingPoint.displayName} key={boardingPoint.id}>
              {boardingPoint.displayName}
            </MenuItem>
          ))}
        </Select>
        <ErrorHelperText meta={meta} />
      </FormControl>
    );
  };

  renderMask = props => <InputMask {...props} />;

  renderCustomInput(props) {
    const isEdition = false;
    const {
      input,
      labelText,
      meta,
      placeholder,
      renderMask,
      required,
      type
    } = props;

    return (
      <>
        <CustomInput
          id={input.name}
          inputProps={input}
          labelText={labelText}
          inputComponent={renderMask}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          required={required}
          formControlProps={{
            fullWidth: true
          }}
          error={isInputError(meta, isEdition)}
          success={isInputSuccess(meta, isEdition)}
        />
        <ErrorHelperText meta={meta} />
      </>
    );
  }

  requireValidator = value => (value ? undefined : "Campo obrigatório");

  phoneValidator = value => {
    if (value) {
      if (value.length < 15) {
        return "Telefone inválido";
      }
    }

    return undefined;
  };

  render() {
    const { alertController, isRequestInProgress, trip, snackbar } = this.state;

    return (
      <React.Fragment>
        {alertController.open ? (
          <AlertDialog
            title={alertController.title}
            content={alertController.content}
            handleDialogCancel={() => {
              this.setState({
                alertController: {
                  open: false
                }
              });
            }}
            handleDialogConfirm={alertController.action}
          />
        ) : null}

        <Snackbar
          place="tc"
          color={snackbar.color}
          icon={ErrorOutline}
          message={snackbar.message}
          open={snackbar.open}
          closeNotification={() => this.setSnackbar(snackbar.color, "", false)}
          close
        />

        <SimpleBackdrop open={isRequestInProgress} />

        {(() => {
          if (!isRequestInProgress) {
            return trip ? (
              <>
                <form
                  onSubmit={this.props.handleSubmit(this.informalReservation)}
                >
                  <Card className={this.props.classes.card}>
                    <CardContent style={{ maxWidth: 345 }}>
                      <Typography gutterBottom variant="h6" component="h2">
                        {formatDatetime(trip.departureTime)}
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px"
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {trip.departure.city}
                        </Typography>
                        <ArrowRightAltIcon
                          style={{ margin: "0 10px 0 10px" }}
                        />
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          {trip.arrival.city}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "30px"
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          O veículo possui {trip.vehicleCapacity} assentos
                        </Typography>
                      </div>
                    </CardContent>
                    <CardActionArea>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Field
                            required={true}
                            component={this.renderCustomInput}
                            labelText="Nome"
                            name="name"
                            placeholder="Digite o nome"
                            type="text"
                            validate={this.requireValidator}
                          />
                          <Field
                            required={false}
                            component={this.renderCustomInput}
                            labelText="Telefone"
                            name="phone"
                            placeholder="Digite o telefone"
                            type="text"
                            renderMask={this.renderMask}
                            validate={this.phoneValidator}
                          />
                          <Field
                            required={true}
                            component={this.renderDepartureSelect}
                            labelText="Local de embarque"
                            name="departureLocation"
                            type="text"
                            validate={this.requireValidator}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardActionArea>
                    <CardFooter
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 0,
                        padding: 0
                      }}
                    >
                      <ButtonLoading
                        type="submit"
                        variant="contained"
                        disabled={trip.status === "CANCELLED"}
                        style={{
                          width: "100%",
                          backgroundColor:
                            trip.status === "CANCELLED"
                              ? grayColor[1]
                              : infoColor[0]
                        }}
                      >
                        Adicionar passageiro
                      </ButtonLoading>
                    </CardFooter>
                  </Card>
                </form>
                <Can
                  perform={"trip:cancel-confirm"}
                  yes={() => (
                    <div>
                      <ButtonLoading
                        onClick={() =>
                          this.onClick(
                            Messages.CONFIRM_TRIP_TITLE,
                            Messages.CONFIRM_TRIP_CONTENT,
                            this.confirmTrip
                          )
                        }
                        disabled={
                          trip.status === "CONFIRMED" ||
                          trip.status === "CANCELLED"
                        }
                        variant="contained"
                        style={{
                          width: "100%",
                          display: "flex",
                          backgroundColor:
                            trip.status === "CONFIRMED" ||
                            trip.status === "CANCELLED"
                              ? grayColor[1]
                              : successColor[0]
                        }}
                      >
                        Confirmar viagem
                      </ButtonLoading>
                      <ButtonLoading
                        onClick={() =>
                          this.onClick(
                            Messages.CANCEL_TRIP_TITLE,
                            Messages.CANCEL_TRIP_CONTENT,
                            this.cancelTrip
                          )
                        }
                        disabled={trip.status === "CANCELLED"}
                        variant="contained"
                        style={{
                          width: "100%",
                          display: "flex",
                          backgroundColor:
                            trip.status === "CANCELLED"
                              ? grayColor[1]
                              : dangerColor[0]
                        }}
                      >
                        Cancelar viagem
                      </ButtonLoading>
                    </div>
                  )}
                  no={() => null}
                />
                <Can
                  perform={"trip:change-vehicle"}
                  yes={() => (
                    <Link to={InternalUrl.CHANGE_VEHICLE(trip.id)}>
                      <ButtonLoading
                        onClick={() =>
                          this.onClick(
                            Messages.CONFIRM_TRIP_TITLE,
                            Messages.CONFIRM_TRIP_CONTENT,
                            this.confirmTrip
                          )
                        }
                        disabled={trip.status === "CANCELLED"}
                        variant="contained"
                        style={{
                          width: "100%",
                          display: "flex",
                          backgroundColor:
                            trip.status === "CANCELLED"
                              ? grayColor[1]
                              : infoColor[0]
                        }}
                      >
                        Trocar veículo
                      </ButtonLoading>
                    </Link>
                  )}
                  no={() => null}
                />
              </>
            ) : (
              <Typography
                variant="body2"
                color="textSecondary"
                component="p"
                align={"center"}
              >
                Não encontramos nenhuma viagem
              </Typography>
            );
          }
        })()}
      </React.Fragment>
    );
  }
}

ManageTrip.propTypes = {
  classes: PropTypes.object,
  clearForm: PropTypes.func,
  handleSubmit: PropTypes.func,
  tripFound: PropTypes.shape({
    informallyReservedSeats: PropTypes.string
  }),
  trips: PropTypes.array
};

const mapStateToProps = state => ({
  trips: _.get(state, "trips.trips.data.trips", [])
});

const mapDispatchToProps = dispatch => ({
  clearForm: () => {
    dispatch(reset("ManageTrip"));
  }
});

export default reduxForm({ form: "ManageTrip" })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(useStyles)(ManageTrip))
);
