import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { change, Field, reduxForm } from "redux-form";
import PropTypes from "prop-types/prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import { InternalUrl, ServerStatus } from "consts";
import { withStyles } from "@material-ui/core";
import ButtonLoading from "components/CustomButtons/ButtonLoading";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { fetchConditions } from "promises/promises";
import {
  createErrorMessageSelector,
  createLoadingSelector
} from "common/redux/loading/selectors";

import VehicleActions from "views/Vehicle/redux/VehicleActions";
import { ErrorOutline } from "@material-ui/icons";
import Snackbar from "../../../components/Snackbar/Snackbar";

const useStyles = theme => ({
  root: {
    display: "flex"
  },
  formControl: {
    margin: theme.spacing(1)
  }
});

class VehicleConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comforts: [],
      restrictions: [],
      allConditions: [],
      snackbar: { color: "danger", message: "", open: false }
    };
  }

  // TODO: handle in errors cases
  componentDidMount() {
    fetchConditions().then(response => {
      if (response.status === ServerStatus.SUCCESS) {
        const conditions = response.data;
        this.setState({
          comforts: conditions.filter(c => c.type === "COMFORT"),
          restrictions: conditions.filter(c => c.type === "RESTRICTION"),
          allConditions: conditions
        });
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { snackbar } = this.state;
    const { failureToEdit } = this.props;

    if (failureToEdit && !snackbar.open) {
      this.handleSnackbar("danger", failureToEdit, true);
      this.props.clearError(VehicleActions.EDIT_VEHICLE_CLEAR_FAILURE);
    }

    // Close snackbar after 3 seconds
    setTimeout(() => {
      this.handleSnackbar(this.state.color, "", false);
    }, 5000);

    if (this.props.vehicle && !prevProps.dirty) {
      this.state.allConditions.forEach(condition => {
        this.props.setConditions(`${condition.id}`, false);
      });
      this.props.vehicle.conditions.forEach(condition => {
        this.props.setConditions(`${condition.id}`, true);
      });
    }
  }

  handleSnackbar = (color, message, open) => {
    this.setState({ snackbar: { color, message, open } });
  };

  onSubmit = formValues => {
    const conditions = { ...formValues };
    const vehicle = {
      id: this.props.vehicle.id,
      conditions: []
    };
    Object.keys(conditions)
      .filter(i => conditions[i] === true)
      .map(id => {
        return vehicle.conditions.push({ id: id });
      });
    this.props.onSubmit(vehicle);
  };

  renderCheckbox = ({ input, label }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            label={"label"}
            checked={Boolean(input.value)}
            onChange={input.onChange}
          />
        }
        label={label}
      />
    );
  };

  render() {
    const { classes, isFetching } = this.props;
    const { comforts, restrictions } = this.state;

    const { color, open, message } = this.state.snackbar;

    return (
      <>
        <Snackbar
          place="tl"
          color={color}
          icon={ErrorOutline}
          message={message}
          open={open}
          closeNotification={() => this.handleSnackbar(color, "", false)}
          close
        />

        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" style={{ color: "green" }}>
                  Comodidades
                </FormLabel>
                <FormGroup>
                  {comforts.map(condition => {
                    return (
                      <Field
                        key={condition.id}
                        name={`${condition.id}`}
                        component={this.renderCheckbox}
                        label={condition.description}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" style={{ color: "red" }}>
                  Restrições
                </FormLabel>
                <FormGroup>
                  {restrictions.map(condition => {
                    return (
                      <Field
                        key={condition.id}
                        name={`${condition.id}`}
                        component={this.renderCheckbox}
                        label={condition.description}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </GridItem>
          </GridContainer>
          <CardFooter>
            <Link to={InternalUrl.VEHICLES.LIST}>
              <ButtonLoading
                variant="contained"
                color={"secondary"}
                style={{
                  width: "120px",
                  marginRight: 5
                }}
              >
                Cancelar
              </ButtonLoading>
            </Link>
            <ButtonLoading
              type="submit"
              variant="contained"
              loading={isFetching}
              style={{ width: "120px" }}
            >
              Salvar
            </ButtonLoading>
          </CardFooter>
        </form>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([VehicleActions.EDIT_VEHICLE]);
const errorEdit = createErrorMessageSelector([VehicleActions.EDIT_VEHICLE]);

VehicleConditions.propTypes = {
  classes: PropTypes.object,
  dirty: PropTypes.bool,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  setConditions: PropTypes.func,
  vehicle: PropTypes.object,
  isFetching: PropTypes.bool,
  failureToEdit: PropTypes.string,
  clearError: PropTypes.func
};

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  vehicle: state.vehicle.vehicle.data,
  failureToEdit: errorEdit(state)
});

const mapDispatchToProps = dispatch => {
  return {
    setConditions: (fieldName, value) =>
      dispatch(change("ConditionForm", fieldName, value)),
    clearError: VehicleActions.clearError
  };
};

export default reduxForm({
  form: "ConditionForm"
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(useStyles)(VehicleConditions))
);
