export const rules = {
  SUPERUSER: {
    static: [
      "vehicle:create",
      "vehicle:delete",
      "vehicle:edit",
      "trip:create",
      "trip:manage",
      "trip:clone",
      "trip:change-vehicle",
      "trip:view-price",
      "trip:cancel-confirm"
    ]
  },
  OWNER: {
    static: [
      "trip:create", 
      "trip:manage", 
      "trip:clone", 
      "trip:change-vehicle", 
      "trip:cancel-confirm"
    ]
  }
};
