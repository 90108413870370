import React from "react";
import Button from "components/CustomButtons/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import Divider from "@material-ui/core/Divider";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { InternalUrl } from "consts";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: "20px"
  },
  cardFooter: {
    display: "flex",
    justifyContent: "center"
  },
  centeredText: {
    textAlign: "center"
  }
}));

export default function F2FPayment() {
  const classes = useStyles();

  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card className={classes.card}>
            <CardHeader>
              <Typography
                className={classes.centeredText}
                gutterBottom
                variant="h5"
                component="h2"
              >
                Solicitações Pendentes
              </Typography>
            </CardHeader>
            <Divider style={{ margin: "10px" }} />
            <CardContent>
              <Typography
                className={classes.centeredText}
                gutterBottom
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Aqui são listados todos pedidos para o pagamento presencial.
                Lembre-se, antes de qualquer aprovação certifique-se de conhecer
                o beneficiário.
              </Typography>
            </CardContent>
            <CardFooter className={classes.cardFooter}>
              <Link to={InternalUrl.F2F_REQUEST}>
                <Button style={{ backgroundColor: "green" }}>Gerenciar</Button>
              </Link>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card className={classes.card}>
            <CardHeader>
              <Typography
                className={classes.centeredText}
                gutterBottom
                variant="h5"
                component="h2"
              >
                Revogar Benefício
              </Typography>
            </CardHeader>
            <Divider style={{ margin: "10px" }} />
            <CardContent>
              <Typography
                className={classes.centeredText}
                gutterBottom
                variant="body2"
                color="textSecondary"
                component="p"
              >
                Aqui é possível revogar o benefício de qualquer usuário, o que
                deve ser feito assim que um passageiro desonrar sua reserva sem
                justificativa.
              </Typography>
            </CardContent>
            <CardFooter className={classes.cardFooter}>
              <Link to={InternalUrl.F2F_REVOKE}>
                <Button style={{ backgroundColor: "red" }}>Revogar</Button>
              </Link>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );
}
