import NotificationActions from "common/redux/notifications/NotificationActions";

const INITIAL_STATE = {
  chat: [],
  reservation: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case NotificationActions.CHAT:
      return { ...state, chat: payload };

    case NotificationActions.NEW_RESERVATION:
      return { ...state, reservation: payload };

    case NotificationActions.CANCEL_RESERVATION:
      return { ...state, reservation: payload };

    case NotificationActions.READ_CHAT_NOTIFICATION:
      return { ...state, chat: payload };

    case NotificationActions.READ_RESERVATION_NOTIFICATION:
      return { ...state, reservation: payload };

    default:
      return state;
  }
};
