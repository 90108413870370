import React, { Component } from "react";
import PropTypes from "prop-types/prop-types";
// @material-ui/core components
import { FormHelperText } from "@material-ui/core";

export const isInputError = (meta, isEdition) => {
  const { dirty, error, touched } = meta;
  return isEdition
    ? !!error
    : !!((dirty && error) || (touched && error === "Campo obrigatório"));
};

export const isInputSuccess = (meta, isEdition) => {
  const { dirty } = meta;
  return isEdition ? !isInputError(meta) : !isInputError(meta) && dirty;
};

class ErrorHelperText extends Component {
  render() {
    const { meta } = this.props;
    return isInputError(meta) ? (
      <FormHelperText error style={{ margin: "0" }}>
        {meta.error}
      </FormHelperText>
    ) : null;
  }
}

ErrorHelperText.propTypes = {
  meta: PropTypes.object
};

export default ErrorHelperText;
