import React from "react";
import { Skeleton } from "@material-ui/lab";

export default function Skeletons(props) {
  const { amount, height, maxWidth, marginBottom } = props;

  return Array.from(new Array(amount)).map((item, index) => (
    <div key={index}>
      <Skeleton
        variant="rect"
        style={{
          marginBottom: marginBottom || "20px",
          maxWidth: maxWidth,
          height: height
        }}
      />
    </div>
  ));
}
