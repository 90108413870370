import React, { useState } from "react";
import NotificationActions from "common/redux/notifications/NotificationActions";
import classNames from "classnames";
import { history } from "common/config";
import { InternalUrl } from "consts";
import { markNotificationRead } from "promises";
import PropTypes from "prop-types/prop-types";
import { removeObject } from "helpers/arrHelper";
import styles from "_assets/jss/components/headerLinksStyle";
import { useDispatch } from "react-redux";

import Button from "components/CustomButtons/Button";

import {
  ClickAwayListener,
  Grow,
  IconButton,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@material-ui/core";

import { Close } from "@material-ui/icons";
import { calculateRelativeTimeFromPast } from "../../helpers/dateTimeHelper";

const useStyles = makeStyles(styles);

export default function Notification(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openNotification, setOpenNotification] = useState(null);

  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const markRead = (notification, redirect) => {
    // Read object
    markNotificationRead(notification).then(response => {
      // Remove from notifications and update redux store
      const payload = removeObject(props.notifications, notification);
      dispatch({ type: props.action, payload: payload });

      if (redirect) {
        handleCloseNotification();
        if (
          props.action === NotificationActions.READ_RESERVATION_NOTIFICATION
        ) {
          history.push(InternalUrl.TRIPS.RESERVATIONS(notification.resourceId));
        }
        if (props.action === NotificationActions.READ_CHAT_NOTIFICATION) {
          history.push(InternalUrl.CHAT_MESSAGE(notification.resourceId));
          window.location.reload();
        }
      }
    });
  };

  return (
    <div className={classes.manager}>
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-owns={openNotification ? "notification-menu-list-grow" : null}
        aria-haspopup="true"
        onClick={handleClickNotification}
        style={{ width: "5px", padding: "15px" }}
      >
        {props.children}
        {props.notifications && props.notifications.length > 0 ? (
          <span className={classes.notifications}>
            {props.notifications.length}
          </span>
        ) : null}
      </Button>
      <Popper
        open={Boolean(openNotification)}
        anchorEl={openNotification}
        transition
        disablePortal
        className={
          classNames({ [classes.popperClose]: !openNotification }) +
          " " +
          classes.popperNav
        }
      >
        {props.notifications && props.notifications.length > 0 ? (
          ({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper className={classes.notificationPaper}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList
                    role="menu"
                    className={classes.notificationMenuList}
                  >
                    {props.notifications &&
                      props.notifications.map((notification, index) => {
                        if (index > 4) return null;
                        return (
                          <div
                            className={classes.menuItemWrapper}
                            key={notification.id}
                          >
                            <MenuItem
                              onClick={() => markRead(notification, true)}
                              className={classes.menuItem}
                            >
                              <div
                                className={classes.notificationContentWrapper}
                              >
                                <span className={classes.notificationTime}>
                                  {calculateRelativeTimeFromPast(
                                    notification.createdAt
                                  )}
                                  :
                                </span>
                                <span className={classes.notificationText}>
                                  {notification.value}
                                </span>
                              </div>
                            </MenuItem>
                            <IconButton
                              arial-label="remover"
                              className={classes.actionButton}
                              onClick={() => markRead(notification, false)}
                            >
                              <Close className={classes.closeIcon} />
                            </IconButton>
                          </div>
                        );
                      })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )
        ) : (
          <span></span>
        )}
      </Popper>
    </div>
  );
}

Notification.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.node,
  action: PropTypes.string,
  resourceId: PropTypes.string
};
