import React, { Component } from "react";
import TripFormTabs from "views/Trip/components/TabsTrip";

class TripNew extends Component {
  render() {
    return <TripFormTabs />;
  }
}

export default TripNew;
