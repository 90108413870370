import React, { Component } from "react";
import { connect } from "react-redux";
import Hidden from "@material-ui/core/Hidden";
import { InternalUrl, Messages, ServerStatus } from "consts";
import { Link } from "react-router-dom";
import PropTypes from "prop-types/prop-types";

import ActionButtons from "components/ActionButtons/ActionButtons";
import Button from "components/CustomButtons/Button";
import Can from "components/Rules/Can";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CardList from "components/Card/CardList";
import FloatingActionButton from "components/FloatingActionButton/FloatingActionButton";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import Skeletons from "components/Skeleton/Skeletons";
import Snackbar from "components/Snackbar/Snackbar";

import { ErrorOutline } from "@material-ui/icons";

import {
  createErrorMessageSelector,
  createLoadingSelector
} from "common/redux/loading/selectors";
import VehicleActions from "views/Vehicle/redux/VehicleActions";

import styles from "_assets/jss/components/buttonStyle";
import { withStyles } from "@material-ui/core/styles";
import { getParamsFromLocation } from "helpers/routeHelper";

class Vehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: { color: "danger", message: "", open: false }
    };
  }

  componentDidMount() {
    this.props.requestVehicles();

    // Open snackbar when incoming from success request
    const requestSuccess = getParamsFromLocation(
      this.props,
      ServerStatus.REQUEST_SUCCESS,
      null
    );

    this.handleSnackbar(
      requestSuccess === VehicleActions.REMOVE_VEHICLE ? "warning" : "success",
      requestSuccess === VehicleActions.REMOVE_VEHICLE
        ? Messages.SUCCESS_VEHICLE_REMOVED
        : Messages.SUCCESS_VEHICLE_EDITED,
      !!requestSuccess
    );

    // Close snackbar after 3 seconds
    setTimeout(() => {
      this.handleSnackbar(this.state.color, "", false);
    }, 3000);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { snackbar } = this.state;
    const { failureToGet, failureToRemove } = this.props;

    if (failureToGet && !snackbar.open) {
      this.handleSnackbar("danger", failureToGet, true);
      this.props.clearError(VehicleActions.GET_VEHICLES_CLEAR_FAILURE);
    }

    if (failureToRemove && !snackbar.open) {
      this.handleSnackbar("danger", failureToRemove, true);
      this.props.clearError(VehicleActions.REMOVE_VEHICLE_CLEAR_FAILURE);
    }

    // TODO: Fix update component
    // Close snackbar after 3 seconds
    setTimeout(() => {
      this.handleSnackbar(this.state.color, "", false);
    }, 3000);
  }

  handleSnackbar = (color, message, open) => {
    this.setState({ snackbar: { color, message, open } });
  };

  // To render in Desktop
  renderTable = vehicles => {
    const vehiclesTable = [];

    // TODO: loading skeleton for desktop
    if (vehicles) {
      vehicles.map(vehicle => {
        return vehiclesTable.push([
          vehicle.model,
          vehicle.plateNumber,
          vehicle.city.displayName,
          vehicle.year,
          vehicle.seats,
          <ActionButtons
            key={vehicle.id}
            editLink={`${InternalUrl.VEHICLES.EDIT}/${vehicle.id}`}
            removeLink={`${InternalUrl.VEHICLES.REMOVE}/${vehicle.id}`}
          />
        ]);
      });
    }

    return (
      <Table
        tableHeaderColor="warning"
        tableHead={["MODELO", "PLACA", "CIDADE", "ANO FAB", "ASSENTOS", ""]}
        tableData={vehiclesTable}
      />
    );
  };

  // To render in Mobile
  renderCard = vehicles => {
    if (this.props.isFetching) {
      return <Skeletons amount={4} height={265} maxWidth={345} />;
    }

    return vehicles.length > 0 ? (
      vehicles.map(vehicle => {
        return (
          <div key={vehicle.id}>
            <CardList vehicle={vehicle} />
          </div>
        );
      })
    ) : this.props.failureToGet ? null : (
      <p style={{ textAlign: "center" }}>{Messages.EMPTY_VEHICLES_LIST}</p>
    );
  };

  render() {
    const { classes } = this.props;
    const vehicles = this.props.vehicles;
    const { color, open, message } = this.state.snackbar;

    return (
      <>
        <Snackbar
          place="tl"
          color={color}
          icon={ErrorOutline}
          message={message}
          open={open}
          closeNotification={() => this.handleSnackbar(color, "", false)}
          close
        />

        <Hidden xsDown implementation="css">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary" stats icon>
                  <Hidden xsDown implementation="css">
                    <Can
                      perform={"vehicle:create"}
                      yes={() => (
                        <Link to={InternalUrl.VEHICLES.NEW}>
                          <Button className={classes.addButton}>
                            Adicionar
                          </Button>
                        </Link>
                      )}
                      no={() => null}
                    />
                  </Hidden>
                </CardHeader>
                <CardBody>{this.renderTable(vehicles)}</CardBody>
                <CardFooter />
              </Card>
            </GridItem>
          </GridContainer>
        </Hidden>

        <Hidden smUp>
          {this.renderCard(vehicles)}
          <Can
            perform={"vehicle:create"}
            yes={() => (
              <Link to={InternalUrl.VEHICLES.NEW}>
                <FloatingActionButton />
              </Link>
            )}
            no={() => null}
          />
        </Hidden>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([VehicleActions.GET_VEHICLES]);
const errorGet = createErrorMessageSelector([VehicleActions.GET_VEHICLES]);
const errorRemove = createErrorMessageSelector([VehicleActions.REMOVE_VEHICLE]);

const mapStateToProps = state => ({
  vehicles: state.vehicle.list,
  isFetching: loadingSelector(state),
  failureToGet: errorGet(state),
  failureToRemove: errorRemove(state)
});

Vehicles.propTypes = {
  classes: PropTypes.object,
  clearError: PropTypes.func,
  failureToGet: PropTypes.string,
  failureToRemove: PropTypes.string,
  isFetching: PropTypes.bool,
  vehicles: PropTypes.array,
  requestVehicles: PropTypes.func
};

export default connect(mapStateToProps, {
  requestVehicles: VehicleActions.requestVehicles,
  clearError: VehicleActions.clearError
})(withStyles(styles)(Vehicles));
