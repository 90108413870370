import _ from "lodash";

export const getParamsFromUrl = ({ match }, paramName) => {
  const { params } = match;
  return params[paramName];
};

export const getParamsFromLocation = (props, paramName, fallback) => {
  return _.get(props, `location.state.${paramName}`, fallback);
};
