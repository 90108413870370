import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types/prop-types";
import { change, Field } from "redux-form";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";

import ErrorHelperText from "components/Error/ErrorHelperText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import SimpleBackdrop from "components/backdrop/Backdrop";

import {
  fetchCompanies,
  fetchConditions,
  fetchVehicles
} from "promises/promises";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class TripConditionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleId: "",
      vehicleList: [],
      comforts: [],
      restrictions: [],
      allConditions: [],
      companies: [],
      showBackdrop: false
    };
  }

  componentDidMount() {
    fetchCompanies().then(data => {
      this.setState({ companies: data.data });
    });
    fetchConditions().then(data => {
      const conditions = data.data;
      this.setState({
        comforts: conditions.filter(c => c.type === "COMFORT"),
        restrictions: conditions.filter(c => c.type === "RESTRICTION"),
        allConditions: conditions
      });
    });
  }

  handleVehicleSelected = event => {
    this.setState({ vehicleId: event.target.value });
    this.props.setVehicle(event.target.value);
    const vehicle = this.state.vehicleList.find(v => {
      return v.id === event.target.value;
    });
    this.state.allConditions.forEach(condition => {
      this.props.setConditions(`conditions.${condition.id}`, false);
    });
    vehicle.conditions.forEach(condition => {
      this.props.setConditions(`conditions.${condition.id}`, true);
    });
    this.props.setTravelVehicle(vehicle);
  };

  handleCompanySelected = event => {
    this.setState({ showBackdrop: true });
    fetchVehicles()
      .then(data => {
        this.setState({
          vehicleList: data.data.filter(
            v => v.company.id === event.target.value
          ),
          showBackdrop: false
        });
      })
      .catch(err => {
        this.setState({ showBackdrop: false });
      });
  };

  renderCompanySelect = ({ input, meta }) => {
    return (
      <FormControl className={this.props.classes.formControl}>
        <InputLabel id="company">Empresa</InputLabel>
        <Select {...input} id="company-select" value={input.value}>
          {this.state.companies.map(c => {
            return (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            );
          })}
        </Select>
        <ErrorHelperText meta={meta} />
      </FormControl>
    );
  };

  renderVehicleSelect = ({ input, children, meta }) => {
    return (
      <FormControl className={this.props.classes.formControl}>
        <InputLabel id="vehicle">Veículo</InputLabel>
        <Select
          {...input}
          style={{ marginBottom: "10px" }}
          id="vehicle-select"
          value={this.state.vehicleId}
        >
          {children}
        </Select>
        <ErrorHelperText meta={meta} />
      </FormControl>
    );
  };

  renderCheckbox = ({ input, label }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            label={"label"}
            checked={Boolean(input.value)}
            onChange={input.onChange}
          />
        }
        label={label}
      />
    );
  };

  requiredValidator = value => (value ? undefined : "Campo obrigatório");

  render() {
    const { classes } = this.props;
    const { comforts, restrictions, showBackdrop } = this.state;
    if (showBackdrop) {
      return <SimpleBackdrop open={true} />;
    } else {
      return (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                name="company.id"
                component={this.renderCompanySelect}
                label="Empresa"
                onChange={this.handleCompanySelected}
                validate={this.requiredValidator}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Field
                classes={classes}
                name="vehicle"
                component={this.renderVehicleSelect}
                label="Veículo"
                onChange={this.handleVehicleSelected}
                validate={this.requiredValidator}
              >
                {this.state.vehicleList.map(v => {
                  return (
                    <MenuItem key={v.id} value={v.id}>
                      {v.model} - {v.plateNumber}
                    </MenuItem>
                  );
                })}
              </Field>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" style={{ color: "green" }}>
                  Comodidades
                </FormLabel>
                <FormGroup>
                  {comforts.map(condition => {
                    return (
                      <Field
                        key={condition.id}
                        name={`conditions.${condition.id}`}
                        component={this.renderCheckbox}
                        label={condition.description}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" style={{ color: "red" }}>
                  Restrições
                </FormLabel>
                <FormGroup>
                  {restrictions.map(restriction => {
                    return (
                      <Field
                        key={restriction.id}
                        name={`conditions.${restriction.id}`}
                        component={this.renderCheckbox}
                        label={restriction.description}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            </GridItem>
          </GridContainer>
        </>
      );
    }
  }
}

TripConditionForm.propTypes = {
  classes: PropTypes.object,
  setVehicle: PropTypes.func,
  setConditions: PropTypes.func,
  setTravelVehicle: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    setVehicle: id => dispatch(change("TripForm", "vehicle.id", id)),
    setConditions: (fieldName, value) =>
      dispatch(change("TripForm", fieldName, value))
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(TripConditionForm));
