import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types/prop-types";
import { primaryColor } from "_assets/jss/materialAdmin";

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(3, 0, 2),
    position: "relative"
  },
  buttonProgress: {
    color: primaryColor[0],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

export default function ButtonLoading(props) {
  const classes = useStyles();

  const {
    color,
    round,
    children,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    loading,
    ...rest
  } = props;

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes.round]: round,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className
  });

  return (
    <div className={classes.wrapper}>
      <Button
        color={color || "primary"}
        disabled={loading || disabled}
        classes={muiClasses}
        className={btnClasses}
        {...rest}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
}

ButtonLoading.propTypes = {
  size: PropTypes.oneOf(["sm", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  children: PropTypes.node,
  loading: PropTypes.bool,
  color: PropTypes.string
};
