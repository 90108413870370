import "moment/locale/pt-br";
import moment from "moment";

import { BACKEND_DATETIME_FORMAT, DEFAULT_DATETIME_FORMAT } from "consts";

export const datetimeToMoment = datetime => {
  return moment(datetime, BACKEND_DATETIME_FORMAT);
};

export const calculateRelativeTimeFromPast = dateTime => {
  return moment(dateTime, BACKEND_DATETIME_FORMAT).fromNow();
};

export const formatDatetime = (datetime, optionalFormat) => {
  return moment(datetime, BACKEND_DATETIME_FORMAT).format(
    optionalFormat ? optionalFormat : DEFAULT_DATETIME_FORMAT
  );
};

export const formatDatetimeToBackend = dateTime => {
  return moment(dateTime, BACKEND_DATETIME_FORMAT).format("DD-MM-YYYY HH:mm");
};

export const isDatePast = date => {
  return moment(date, BACKEND_DATETIME_FORMAT).isBefore(moment());
};
