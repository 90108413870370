import React, { Component } from "react";
import { Link } from "react-router-dom";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";
import styles from "_assets/jss/components/ActionButtonsStyle";
import PropTypes from "prop-types/prop-types";
import Can from "components/Rules/Can";
import { Close, Edit, Search } from "@material-ui/icons";

class ActionButtons extends Component {
  render() {
    const { classes, editLink, removeLink, detailsLink } = this.props;

    return (
      <div className={classes.tableActions}>
        {editLink ? (
          <Can
            perform={"vehicle:edit"}
            yes={() => (
              <Tooltip
                id="edit"
                title="Editar"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Link to={editLink}>
                  <IconButton
                    arial-label="editar"
                    className={classes.tableActionButton}
                  >
                    <Edit
                      className={
                        classes.tableActionButtonIcon + " " + classes.edit
                      }
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            no={() => null}
          />
        ) : null}

        {removeLink ? (
          <Can
            perform={"vehicle:delete"}
            yes={() => (
              <Tooltip
                id="remove"
                title="Remover"
                placement="top"
                classes={{ tooltip: classes.tooltip }}
              >
                <Link to={removeLink}>
                  <IconButton
                    arial-label="remover"
                    className={classes.tableActionButton}
                  >
                    <Close
                      className={
                        classes.tableActionButtonIcon + " " + classes.close
                      }
                    />
                  </IconButton>
                </Link>
              </Tooltip>
            )}
            no={() => null}
          />
        ) : null}

        {detailsLink ? (
          <Tooltip
            id="view"
            title="Visualizar"
            placement="top"
            classes={{ tooltip: classes.tooltip }}
          >
            <Link to={detailsLink}>
              <IconButton
                arial-label="visualizar"
                className={classes.tableActionButton}
              >
                <Search
                  className={
                    classes.tableActionButtonIcon + " " + classes.search
                  }
                />
              </IconButton>
            </Link>
          </Tooltip>
        ) : null}
      </div>
    );
  }
}

ActionButtons.propTypes = {
  editLink: PropTypes.string,
  removeLink: PropTypes.string,
  detailsLink: PropTypes.string,
  classes: PropTypes.object
};

export default withStyles(styles)(ActionButtons);
