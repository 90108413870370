import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { InternalUrl } from "consts";
import { Link } from "react-router-dom";
import PropTypes from "prop-types/prop-types";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

import CityAutoComplete from "components/AutoComplete/CityAutoComplete";
import ButtonLoading from "components/CustomButtons/ButtonLoading";
import CustomInput from "components/CustomInput/CustomInput";
import CardFooter from "components/Card/CardFooter";
import ErrorHelperText, {
  isInputError,
  isInputSuccess
} from "components/Error/ErrorHelperText";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputMask from "components/InputMask/InputMask";

import {
  createErrorMessageSelector,
  createLoadingSelector
} from "common/redux/loading/selectors";
import VehicleActions from "../redux/VehicleActions";
import { ErrorOutline } from "@material-ui/icons";
import Snackbar from "components/Snackbar/Snackbar";

import { fetchCompanies } from "promises/promises";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  formControl: {
    width: "100%",
    marginTop: "27px"
  }
});

class VehicleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: { color: "danger", message: "", open: false },
      companies: []
    };
  }

  componentDidMount() {
    fetchCompanies().then(data => {
      this.setState({ companies: data.data });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { snackbar } = this.state;
    const { failureToSave, failureToEdit } = this.props;

    if (failureToSave && !snackbar.open) {
      this.handleSnackbar("danger", failureToSave, true);
      this.props.clearError(VehicleActions.SAVE_VEHICLE_CLEAR_FAILURE);
    }

    if (failureToEdit && !snackbar.open) {
      this.handleSnackbar("danger", failureToEdit, true);
      this.props.clearError(VehicleActions.EDIT_VEHICLE_CLEAR_FAILURE);
    }

    // Close snackbar after 5 seconds
    setTimeout(() => {
      this.handleSnackbar(this.state.color, "", false);
    }, 5000);
  }

  handleSnackbar = (color, message, open) => {
    this.setState({ snackbar: { color, message, open } });
  };

  renderCustomInput = props => {
    const { isEdition } = this.props;
    const { input, labelText, meta, renderMask, type, placeholder } = props;
    return (
      <>
        <CustomInput
          id={input.name}
          inputProps={input}
          labelText={labelText}
          inputComponent={renderMask}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          required={true}
          formControlProps={{
            fullWidth: true
          }}
          error={isInputError(meta, isEdition)}
          success={isInputSuccess(meta, isEdition)}
        />
        <ErrorHelperText meta={meta} />
      </>
    );
  };

  renderCompanySelect = ({ input, meta }) => {
    return (
      <FormControl className={this.props.classes.formControl}>
        <InputLabel id="company">Empresa</InputLabel>
        <Select {...input} id="company-select" value={input.value}>
          {this.state.companies.map(c => {
            return (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            );
          })}
        </Select>
        <ErrorHelperText meta={meta} />
      </FormControl>
    );
  };

  renderMask = props => <InputMask {...props} />;

  renderCityAutoComplete = props => {
    const { isEdition } = this.props;
    const { meta } = props;
    return (
      <CityAutoComplete
        {...props}
        error={isInputError(meta, isEdition)}
        success={isInputSuccess(meta, isEdition)}
      >
        <ErrorHelperText meta={meta} isEdition={this.props.isEdition} />
      </CityAutoComplete>
    );
  };

  onSubmit = formValues => {
    this.props.onSubmit(formValues);
  };

  requiredValidator = value => (value ? undefined : "Campo obrigatório");

  yearValidator = value => {
    const currentYear = new Date().getFullYear();
    const minYear = currentYear - 3;
    if (value > currentYear) {
      return "Ano de fabricação inválido";
    }
    if (value < minYear) {
      return `O ano deve ser superior a ${minYear - 1}`;
    }
    return undefined;
  };

  plateNumberValidator = value =>
    value.length === 8 ? undefined : "Placa inválida";

  cityValidator = (value, formValues) => {
    return value && !formValues.city.id ? "Selecione uma cidade" : undefined;
  };

  seatsValidator = value => {
    if (value < 14) {
      return "Informe um valor entre 14 e 18 assentos";
    }
    if (value > 18) {
      return "O máximo permitido é 18 assentos";
    }
    return undefined;
  };

  render() {
    const {
      isEdition,
      isFetchingEdit,
      isFetchingSave,
      pristine,
      submitting
    } = this.props;

    const { color, open, message } = this.state.snackbar;

    return (
      <>
        <Snackbar
          place="tl"
          color={color}
          icon={ErrorOutline}
          message={message}
          open={open}
          closeNotification={() => this.handleSnackbar(color, "", false)}
          close
        />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <Field
                    name="company.id"
                    component={this.renderCompanySelect}
                    label="Empresa"
                    validate={this.requiredValidator}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <Field
                    component={this.renderCustomInput}
                    labelText="Modelo"
                    name="model"
                    placeholder="Digite o modelo do veículo"
                    type="text"
                    validate={this.requiredValidator}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Field
                    component={this.renderCustomInput}
                    labelText="Ano de fabricação"
                    name="year"
                    placeholder="Digite o ano de fabricação"
                    type="text"
                    renderMask={this.renderMask}
                    validate={[this.requiredValidator, this.yearValidator]}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <Field
                    component={this.renderCustomInput}
                    labelText="Placa"
                    name="plateNumber"
                    placeholder="ABC 1?34"
                    type="text"
                    renderMask={this.renderMask}
                    validate={[
                      this.requiredValidator,
                      this.plateNumberValidator
                    ]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5}>
                  <Field
                    component={props => (
                      <input {...props.input} type="hidden" />
                    )}
                    name="city.id"
                    type="text"
                  />
                  <Field
                    component={this.renderCityAutoComplete}
                    labelText="Cidade"
                    name="city.displayName"
                    type="text"
                    validate={[this.requiredValidator, this.cityValidator]}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <Field
                    component={this.renderCustomInput}
                    labelText="Quantidade de assentos"
                    name="seats"
                    placeholder="Digite o número de assentos"
                    type="text"
                    renderMask={this.renderMask}
                    validate={[this.requiredValidator, this.seatsValidator]}
                  />
                </GridItem>
              </GridContainer>
              <CardFooter>
                <Link to={InternalUrl.VEHICLES.LIST}>
                  <ButtonLoading
                    variant="contained"
                    color={"secondary"}
                    disabled={pristine || submitting}
                    style={{
                      width: "120px",
                      marginRight: 5
                    }}
                  >
                    Cancelar
                  </ButtonLoading>
                </Link>
                <ButtonLoading
                  type="submit"
                  variant="contained"
                  loading={isEdition ? isFetchingEdit : isFetchingSave}
                  disabled={pristine || submitting}
                  style={{ width: "120px" }}
                >
                  Salvar
                </ButtonLoading>
              </CardFooter>
            </form>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

const loadingSave = createLoadingSelector([VehicleActions.SAVE_VEHICLE]);
const errorSave = createErrorMessageSelector([VehicleActions.SAVE_VEHICLE]);
const loadingEdit = createLoadingSelector([VehicleActions.EDIT_VEHICLE]);
const errorEdit = createErrorMessageSelector([VehicleActions.EDIT_VEHICLE]);

VehicleForm.propTypes = {
  isEdition: PropTypes.bool,
  onSubmit: PropTypes.func,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isFetchingSave: PropTypes.bool,
  isFetchingEdit: PropTypes.bool,
  failureToSave: PropTypes.string,
  failureToEdit: PropTypes.string,
  clearError: PropTypes.func,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  isFetchingSave: loadingSave(state),
  isFetchingEdit: loadingEdit(state),
  failureToSave: errorSave(state),
  failureToEdit: errorEdit(state)
});

export default reduxForm({
  form: "VehicleForm"
})(
  connect(mapStateToProps, { clearError: VehicleActions.clearError })(
    withStyles(styles)(VehicleForm)
  )
);
