import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types/prop-types";
// @material-ui components
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// components
import CustomInput from "components/CustomInput/CustomInput";
// Utils
import DateFnsUtils from "@date-io/date-fns";
import ptLocale from "date-fns/locale/pt-BR";
import _ from "lodash";

class DateTime extends Component {
  renderCustomInput = input => {
    const { children, error, success } = this.props;
    return (
      <>
        <CustomInput
          id={input.name}
          labelText="Data e hora"
          placeholder="Selecione a data e hora"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={input}
          required={true}
          type={"text"}
          success={success}
          error={error}
        />
        {children}
      </>
    );
  };

  render() {
    const { input, TripForm } = this.props;
    const minDate =
      input.name === "departureTime"
        ? new Date()
        : _.get(TripForm, "values.departureTime", new Date());
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <DateTimePicker
          disablePast
          minDate={minDate}
          value={input.value || null}
          onChange={input.onChange}
          label="Data e hora"
          placeholder="Selecione a data e hora"
          ampm={false}
          cancelLabel={"Cancelar"}
          format={"dd/MM/yyyy 'às' HH:mm"}
          TextFieldComponent={this.renderCustomInput}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

DateTime.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  labelText: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.bool,
  success: PropTypes.bool,
  minDate: PropTypes.string,
  TripForm: PropTypes.object
};

const mapStateToProps = state => ({
  TripForm: state.form.TripForm
});

export default connect(mapStateToProps)(DateTime);
