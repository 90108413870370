import React, { Component } from "react";
import { connect } from "react-redux";
import { ServerStatus } from "consts";
import { change } from "redux-form";
import PropTypes from "prop-types/prop-types";
import Autosuggest from "react-autosuggest";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import CustomInput from "components/CustomInput/CustomInput";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { withStyles } from "@material-ui/core/styles";

import { fetchCitiesAutoComplete } from "promises/promises";

import { infoColor } from "_assets/jss/materialAdmin";
import styles from "_assets/jss/components/AutoCompleteStyle";

class CityAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      suggestions: []
    };
  }

  renderSuggestion = (props, { query }) => {
    this.props.setId("");
    const suggestionText = props.displayName;
    const matches = match(suggestionText, query);
    const parts = parse(suggestionText, matches);

    return (
      <MenuItem component="div">
        <span className="name">
          {parts.map((part, index) => {
            return (
              <span
                style={{
                  color: part.highlight ? infoColor[1] : null,
                  fontWeight: part.highlight ? "bold" : null
                }}
                key={index}
              >
                {part.text}
              </span>
            );
          })}
        </span>
      </MenuItem>
    );
  };

  getSuggestionValue = suggestion => suggestion.displayName;

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // TODO: handle in errors cases
  onSuggestionsFetchRequested = ({ value }) => {
    fetchCitiesAutoComplete(value).then(response => {
      if (response.status === ServerStatus.SUCCESS) {
        const cities = response.data;
        this.setState({
          suggestions: cities
        });
      }
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, method }) => {
    const { input } = this.props;
    const { id } = suggestion;
    input.onChange(suggestionValue);
    this.props.setId(id);
    if (method === "enter") {
      event.preventDefault();
    }
  };

  renderCustomInput = inputProps => {
    const { children, error, success } = this.props;
    return (
      <>
        <CustomInput
          labelText="Cidade"
          placeholder="Digite a cidade de emplacamento"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={inputProps}
          required={true}
          type={"text"}
          error={error}
          success={success}
        />
        {children}
      </>
    );
  };

  render() {
    const { classes, input } = this.props;
    const { suggestions } = this.state;

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderCustomInput}
        inputProps={input}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    );
  }
}

CityAutoComplete.propTypes = {
  setId: PropTypes.func,
  children: PropTypes.node,
  error: PropTypes.bool,
  success: PropTypes.bool,
  input: PropTypes.object,
  classes: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
  setId: id => dispatch(change("VehicleForm", "city.id", id))
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CityAutoComplete));
