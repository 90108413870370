import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types/prop-types";
import SendIcon from "@material-ui/icons/Send";
import { sendChatMessage } from "promises";
import TextField from "@material-ui/core/TextField";
import { infoColor, whiteColor } from "_assets/jss/materialAdmin";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginTop: 10
  },
  input: {
    marginRight: 10,
    flex: 1,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "30px 30px 30px 30px"
      }
    }
  },
  iconButton: {
    color: whiteColor,
    backgroundColor: infoColor[0]
  }
}));

export default function MessageInput(props) {
  const classes = useStyles();
  const { chatCode } = props;
  const [message, setMessage] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  function handleInputMessage(e) {
    const value = e.target.value;
    setMessage(value.substr(0, 1000));
  }

  function sendMessage() {
    const newMessage = message.trim();
    if (newMessage) {
      setDisableButton(true);
      sendChatMessage(chatCode, newMessage)
        .then(data => {
          // TODO: handle response
          console.log(data);
        })
        .catch(error => {
          // TODO: handle error
          console.log(error);
        });
      setMessage("");
      setDisableButton(false);
    }
  }

  return (
    <div className={classes.root}>
      <TextField
        className={classes.input}
        placeholder={"Digite uma mensagem"}
        type="text"
        multiline={true}
        rowsMax={4}
        variant={"outlined"}
        value={message}
        onChange={handleInputMessage}
      />
      <IconButton
        style={{ color: whiteColor, backgroundColor: infoColor[0] }}
        className={classes.iconButton}
        onClick={sendMessage}
        disabled={disableButton}
      >
        <SendIcon />
      </IconButton>
    </div>
  );
}

MessageInput.propTypes = {
  chatCode: PropTypes.string
};
