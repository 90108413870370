import React, { useState, useEffect } from "react";
import ButtonLoading from "components/CustomButtons/ButtonLoading";
import CustomInput from "components/CustomInput/CustomInput";
import Snackbar from "components/Snackbar/Snackbar";
import { signIn } from "services/auth";
import { api as axios } from "services";
import { isAuthenticated } from "services/auth";
import { history } from "common/config";
import PropTypes from "prop-types/prop-types";

import { ExternalUrl, InternalUrl, Messages, ServerStatus } from "consts";

import {
  Box,
  Container,
  CssBaseline,
  InputAdornment,
  IconButton,
  Link,
  Typography,
  makeStyles
} from "@material-ui/core";

import { ErrorOutline, Visibility, VisibilityOff } from "@material-ui/icons";

import logo from "_assets/img/logo.png";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  img: {
    width: "130px",
    border: "0",
    margin: "10px 0 10px 0"
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://vaidevan.app">
        www.vaidevan.app
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn(props) {
  const classes = useStyles();
  const { subscribe } = props;
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false
  });
  const [snackbarComponent, setSnackbarComponent] = React.useState({
    open: false,
    message: ""
  });

  const onChangeUsername = event => {
    setUsername(event.target.value);
  };

  const handleSnackbar = (open, message) => {
    setSnackbarComponent({
      open: open,
      message: message
    });
  };

  const onChangePassword = prop => event => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const onAttemptLogin = event => {
    event.preventDefault();
    const { password } = values;

    if (!username.trim() || !password.trim()) {
      handleSnackbar(true, Messages.ERROR_REQUIRED_FIELDS);

      // Close snackbar after 3 seconds
      setTimeout(() => {
        handleSnackbar(false, "");
      }, 3000);

      return;
    }

    setLoading(true);

    axios
      .post(ExternalUrl.SIGN_IN, {
        password,
        username
      })
      .then(data => {
        if (data.status === ServerStatus.SUCCESS) {
          loginSuccess(data.data);
          return;
        }

        if (data.status === ServerStatus.INVALID) {
          handleSnackbar(true, data.data);
        }
      })
      .catch(err => {
        handleSnackbar(true, Messages.ERROR_UNEXPECTED_FROM_SERVER);
      })
      .finally(() => {
        setLoading(false);

        // Close snackbar after 3 seconds
        setTimeout(() => {
          handleSnackbar(false, "");
        }, 3000);
      });
  };

  function loginSuccess(token) {
    signIn(token);
    subscribe(token);
    history.push(InternalUrl.HOME);
  }

  function closeSnackbar() {
    handleSnackbar(false, "");
  }

  const clickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isAuthenticated()) {
      history.push(InternalUrl.HOME);
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <div>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        <form className={classes.form} noValidate onSubmit={onAttemptLogin}>
          <Snackbar
            place="tl"
            color="danger"
            icon={ErrorOutline}
            message={snackbarComponent.message}
            open={snackbarComponent.open}
            closeNotification={() => closeSnackbar()}
            close
          />
          <CustomInput
            id="email"
            name="email"
            labelText="Email"
            type="text"
            placeholder="Digite o email"
            autoComplete="on"
            formControlProps={{
              fullWidth: true
            }}
            onChange={onChangeUsername}
            onFocus={closeSnackbar}
            required
          />
          <CustomInput
            id="password"
            name="password"
            type={values.showPassword ? "text" : "password"}
            placeholder="Digite a senha"
            autoComplete="off"
            value={values.password}
            labelText="Senha"
            onChange={onChangePassword("password")}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="senha revelada"
                  onClick={clickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            formControlProps={{
              fullWidth: true
            }}
            onFocus={closeSnackbar}
            required
          />
          <ButtonLoading
            type="submit"
            variant="contained"
            loading={loading}
            fullWidth
          >
            Entrar
          </ButtonLoading>
        </form>
      </div>
      <Box mt={6}>
        <Copyright />
      </Box>
    </Container>
  );
}

SignIn.propTypes = {
  subscribe: PropTypes.func
};
