/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types/prop-types";
import AlertDialog from "components/AlertDialog/AlertDialog";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import DeleteIcon from "@material-ui/icons/Delete";
import { cpfMask, phoneMask } from "helpers/maskHelper";
import { formatDatetime } from "helpers/dateTimeHelper";
import { getReservation, getReservations } from "promises";
import { Messages, ServerStatus } from "consts";
import Skeletons from "components/Skeleton/Skeletons";
import _ from "lodash";
import IconButton from "@material-ui/core/IconButton";
import { deleteInformalPassenger } from "../../promises/promises";
import { ErrorOutline } from "@material-ui/icons";
import Snackbar from "components/Snackbar/Snackbar";
import SimpleBackdrop from "components/backdrop/Backdrop";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { connect } from "react-redux";

import { dangerColor, successColor } from "_assets/jss/materialAdmin";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    marginBottom: "20px"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));

export function Reservations(props) {
  const classes = useStyles();
  const [reservations] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const { id } = props.match.params;
  const [dialogController, setDialogController] = useState({
    isOpen: false,
    action: null
  });
  const [alert, setAlert] = useState({
    open: false,
    color: null,
    message: ""
  });
  const [isRequestProgress, setRequestProgress] = useState(false);
  const [trip, setTrip] = useState(null);

  // TODO: handle errors in request
  // noinspection JSCheckFunctionSignatures
  useEffect(() => {
    setFetching(true);
    if (isNaN(id)) {
      // From Notification
      getReservation(id).then(data => {
        if (data.status === ServerStatus.SUCCESS) {
          const tripFound = props.trips.find(
            trip => trip.id.toString() === data.data.tripId.toString()
          );

          if (tripFound) {
            setTrip(tripFound);
          }

          reservations.pop();
          _.forEach(data.data.passengers, function(passenger) {
            reservations.push({
              ...passenger,
              code: data.data.code + passenger.id,
              status: data.data.status,
              isInformalReservation: false,
              createdAt: data.data.createdAt,
              bookedBy: {
                name: data.data.bookedBy.name,
                phone: data.data.bookedBy.phone
              }
            });
          });
          setFetching(false);
        }
      });
    } else {
      // From trip
      getReservations(id).then(data => {
        if (data.status === ServerStatus.SUCCESS) {
          if (!_.isEmpty(data.data)) {
            reservations.pop();
            _.forEach(data.data.informalReservations, function(informal) {
              reservations.push({
                ...informal,
                status: "CONFIRMED",
                isInformalReservation: true
              });
            });

            _.forEach(data.data.reservations, function(reservation) {
              _.forEach(reservation.passengers, function(passenger) {
                reservations.push({
                  ...passenger,
                  code: reservation.code + passenger.id,
                  status: reservation.status,
                  isInformalReservation: false,
                  createdAt: reservation.createdAt,
                  bookedBy: {
                    name: reservation.bookedBy.name,
                    phone: reservation.bookedBy.phone
                  }
                });
              });
            });
          }
          setFetching(false);
        }
      });
    }
  }, [id]);

  function removeInformalPassenger(informalPassengerId) {
    setDialogController({
      open: false
    });
    setRequestProgress(true);
    deleteInformalPassenger(informalPassengerId)
      .then(data => {
        if (data.status === ServerStatus.SUCCESS) {
          setAlert({
            color: "success",
            message: "Passageiro removido com sucesso.",
            open: true
          });
          reservations.splice(
            reservations.findIndex(r => r.id === informalPassengerId),
            1
          );
        } else {
          setAlert({
            color: "danger",
            message: data.data,
            open: true
          });
        }
      })
      .catch(error => {
        setAlert({
          color: "danger",
          message: Messages.ERROR_UNEXPECTED_FROM_SERVER,
          open: true
        });
      })
      .finally(() => {
        setRequestProgress(false);
        // Close alert after 3 seconds
        setTimeout(() => {
          setAlert({
            color: alert.color,
            message: "",
            open: false
          });
        }, 3000);
      });
  }

  function openRemoveInformalPassengerDialog(informalPassengerId) {
    setDialogController({
      open: true,
      action: () => removeInformalPassenger(informalPassengerId)
    });
  }

  return (
    <>
      {dialogController.open ? (
        <AlertDialog
          title={"Confirma Remoção"}
          content={"Realmente deseja cancelar essa reserva?"}
          handleDialogCancel={() => setDialogController({ open: false })}
          handleDialogConfirm={dialogController.action}
        />
      ) : null}

      <Snackbar
        place="tc"
        color={alert.color}
        icon={ErrorOutline}
        message={alert.message}
        open={alert.open}
        closeNotification={() =>
          setAlert({ color: alert.color, message: "", open: false })
        }
        close
      />

      <SimpleBackdrop open={isRequestProgress} />

      {trip != null ? (
        <div>
          <Divider style={{ margin: "10px 0 10px 0" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px"
            }}
          >
            <Typography gutterBottom variant="h6" component="h2">
              {formatDatetime(trip.departureTime)}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px"
            }}
          >
            <Typography variant="body2" color="textSecondary" component="p">
              {trip.departure.substr(0, trip.departure.length - 4)}
            </Typography>
            <ArrowRightAltIcon style={{ margin: "0 10px 0 10px" }} />
            <Typography variant="body2" color="textSecondary" component="p">
              {trip.arrival.substr(0, trip.arrival.length - 4)}
            </Typography>
          </div>
          <Divider style={{ margin: "10px 0 35px 0" }} />
        </div>
      ) : null}

      {!isFetching && reservations.length > 0 ? (
        reservations.map(reservation => {
          return (
            <div
              key={
                reservation.isInformalReservation
                  ? reservation.id
                  : reservation.code
              }
            >
              <Card className={classes.card}>
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{
                        color:
                          reservation.status === "CONFIRMED"
                            ? successColor[0]
                            : dangerColor[0],
                        marginTop: "5px",
                        fontWeight: "bold"
                      }}
                    >
                      {reservation.status === "CONFIRMED"
                        ? "CONFIRMADA"
                        : "CANCELADA"}
                    </Typography>
                  </div>
                  <Divider style={{ margin: "10px 0 10px 0" }} />
                  <Typography gutterBottom variant="h6" component="h2">
                    {reservation.name}
                  </Typography>
                  <List>
                    <ListItem>
                      <ListItemAvatar>
                        <ContactPhoneIcon />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          reservation.phone
                            ? phoneMask(reservation.phone)
                            : "Não informado"
                        }
                        secondary={
                          reservation.isInformalReservation
                            ? null
                            : `CPF ${cpfMask(reservation.cpf)}`
                        }
                      />
                    </ListItem>
                  </List>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                    style={{ marginTop: "10px", textAlign: "justify" }}
                  >
                    {"Reservado por "}
                    <span
                      style={{
                        fontWeight: "bold",
                        color: "#007FFF"
                      }}
                    >
                      {reservation.isInformalReservation
                        ? reservation.bookedBy
                        : reservation.bookedBy.name}
                    </span>{" "}
                    no dia{" "}
                    {formatDatetime(reservation.createdAt, "DD/MM [às] HH:mm")}
                  </Typography>
                </CardContent>
                <Divider style={{ margin: "10px 15px 10px 15px" }} />
                {reservation.isInformalReservation ? (
                  <CardActions
                    disableSpacing
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <span
                      style={{
                        margin: "4px 5px 0 5px",
                        color: "red",
                        fontWeight: "bold"
                      }}
                    >
                      Reserva Informal
                    </span>
                    <IconButton
                      aria-label="Remover passageiro"
                      style={{
                        color: dangerColor[0],
                        padding: 0,
                        marginRight: "8px"
                      }}
                      onClick={() =>
                        openRemoveInformalPassengerDialog(reservation.id)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </CardActions>
                ) : (
                  <CardActions disableSpacing>
                    <Icon
                      aria-label="Celular"
                      style={{ color: "red", padding: "2px" }}
                    >
                      <PhoneAndroidIcon style={{ color: "#007FFF" }} />
                    </Icon>
                    <span style={{ margin: "4px 5px 0 5px" }}>
                      {reservation.isInformalReservation
                        ? null
                        : phoneMask(reservation.bookedBy.phone)}
                    </span>
                  </CardActions>
                )}
              </Card>
            </div>
          );
        })
      ) : isFetching ? (
        <Skeletons amount={4} height={365} maxWidth={345} />
      ) : (
        <p style={{ textAlign: "center" }}>{Messages.EMPTY_RESERVATION_LIST}</p>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return { trips: _.get(state, "trips.trips.data.trips", []) };
}

Reservations.propTypes = {
  bookedBy: PropTypes.string,
  createdAt: PropTypes.string,
  match: PropTypes.object,
  passengers: PropTypes.object,
  reservations: PropTypes.object,
  trips: PropTypes.arrayOf(PropTypes.object)
};

export default connect(mapStateToProps)(Reservations);
