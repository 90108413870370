import React, { useState } from "react";
import CustomInput from "components/CustomInput/CustomInput";
import { ErrorOutline } from "@material-ui/icons";
import Snackbar from "components/Snackbar/Snackbar";
import { ExternalUrl, Messages } from "consts";
import { api as axios } from "services";
import ServerStatus from "consts/ServerStatus";
import ButtonLoading from "components/CustomButtons/ButtonLoading";

export default function F2FGrant() {
  const [snackbarComponent, setSnackbarComponent] = React.useState({
    open: false,
    message: "",
    type: "danger"
  });
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  function closeSnackbar() {
    handleSnackbar(false, "");
  }

  const handleSnackbar = (open, message, type) => {
    setSnackbarComponent({
      open: open,
      message: message,
      type: type
    });
  };

  const onChangeUsername = event => {
    setUsername(event.target.value);
  };

  const grantPayment = () => {
    if (!username.trim()) {
      handleSnackbar(true, Messages.ERROR_REQUIRED_FIELDS, "danger");
      return;
    }

    setLoading(true);

    axios
      .post(ExternalUrl.F2F_PAYMENT_GRANT, {
        username: username
      })
      .then(data => {
        if (data.status === ServerStatus.SUCCESS) {
          setUsername("");
          handleSnackbar(
            true,
            "Pagamento presencial concedido com sucesso",
            "success"
          );
        } else if (data.status === ServerStatus.INVALID) {
          handleSnackbar(true, data.data, "danger");
        }
      })
      .catch(err => {
        handleSnackbar(true, Messages.ERROR_UNEXPECTED_FROM_SERVER, "danger");
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          handleSnackbar(false, "");
        }, 3000);
      });
  };

  return (
    <div>
      <Snackbar
        place="tl"
        color={snackbarComponent.type}
        icon={ErrorOutline}
        message={snackbarComponent.message}
        open={snackbarComponent.open}
        closeNotification={() => closeSnackbar()}
        close
      />
      <CustomInput
        id="email"
        name="email"
        labelText="Email"
        type="text"
        placeholder="Digite o email para conceder pagamento presencial"
        autoComplete="on"
        formControlProps={{
          fullWidth: true
        }}
        value={username}
        onChange={onChangeUsername}
        required
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center"
        }}
      >
        <ButtonLoading
          variant="contained"
          loading={loading}
          fullWidth
          style={{
            backgroundColor: "green",
            width: "120px"
          }}
          onClick={grantPayment}
        >
          Conceder
        </ButtonLoading>
      </div>
    </div>
  );
}
