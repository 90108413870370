import React, { Component } from "react";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import CameraAlt from "@material-ui/icons/CameraAlt";
import List from "@material-ui/icons/List";
import CardBody from "components/Card/CardBody";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types/prop-types";

import VehicleForm from "views/Vehicle/containers/VehicleForm";
import VehiclePhoto from "views/Vehicle/containers/VehiclePhoto";
import VehicleConditions from "views/Vehicle/containers/VehicleConditions";

function TabPanel(props) {
  const { children, currentTab, index, ...other } = props;

  return (
    <CardBody
      component="div"
      role="tabpanel"
      hidden={currentTab !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {children}
    </CardBody>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`
  };
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    // paddingTop: "10px",
    // padding: "20px 0",
    position: "static",
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "0",
    width: "100%",
    zIndex: "1029",
    border: "2px",
    borderRadius: "0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  }
});

class VehicleTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0
    };
  }

  handleChangeTab = (event, newValue) => {
    this.setState({ currentTab: newValue });
  };

  render() {
    const { classes, isEdition, onSubmit, vehicle } = this.props;

    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Tabs
            aria-label="scrollable tabs"
            centered={true}
            indicatorColor="primary"
            onChange={this.handleChangeTab}
            scrollButtons="on"
            textColor="primary"
            value={this.state.currentTab}
            // variant="scrollable"
          >
            <Tab
              disabled={false}
              label={window.innerWidth > 960 ? "Veículo" : ""}
              icon={<AirportShuttleIcon />}
              {...a11yProps(0)}
            />
            <Tab
              disabled={!vehicle}
              label={window.innerWidth > 960 ? "Foto" : ""}
              icon={<CameraAlt />}
              {...a11yProps(1)}
            />
            <Tab
              disabled={!vehicle}
              label={window.innerWidth > 960 ? "Comodidades" : ""}
              icon={<List />}
              {...a11yProps(2)}
            />
          </Tabs>
        </AppBar>
        <TabPanel currentTab={this.state.currentTab} index={0}>
          <VehicleForm
            onSubmit={onSubmit}
            isEdition={isEdition}
            initialValues={vehicle}
          />
        </TabPanel>
        <TabPanel currentTab={this.state.currentTab} index={1}>
          <VehiclePhoto />
        </TabPanel>
        <TabPanel currentTab={this.state.currentTab} index={2}>
          <VehicleConditions onSubmit={onSubmit} />
        </TabPanel>
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  currentTab: PropTypes.number
};

VehicleTabs.propTypes = {
  classes: PropTypes.object,
  isEdition: PropTypes.bool,
  vehicle: PropTypes.object,
  onSubmit: PropTypes.func
};

const mapStateToProps = state => ({
  vehicle: state.vehicle.vehicle.data
});

export default connect(mapStateToProps)(withStyles(styles)(VehicleTabs));
