import {
  defaultFont,
  infoColor,
  primaryColor,
  dangerColor,
  grayColor
} from "_assets/jss/materialAdmin";
import tooltipStyle from "_assets/jss/tooltipStyle";

const actionButtonsStyle = {
  ...tooltipStyle,
  table: {
    marginBottom: "0",
    overflow: "visible"
  },
  tableRow: {
    position: "relative",
    borderBottom: "1px solid " + grayColor[5]
  },
  tableActions: {
    display: "flex",
    border: "none",
    verticalAlign: "middle"
  },
  tableCell: {
    ...defaultFont,
    padding: "8px",
    verticalAlign: "middle",
    border: "none",
    lineHeight: "1.42857143",
    fontSize: "14px"
  },
  tableCellRTL: {
    textAlign: "right"
  },
  tableActionButton: {
    width: "27px",
    height: "27px",
    padding: "0",
    margin: "0 5px 0 5px"
  },
  tableActionButtonIcon: {
    width: "20px",
    height: "20px"
  },
  edit: {
    backgroundColor: "transparent",
    color: primaryColor[0],
    boxShadow: "none"
  },
  close: {
    backgroundColor: "transparent",
    color: dangerColor[0],
    boxShadow: "none"
  },
  search: {
    backgroundColor: "transparent",
    color: infoColor[0],
    boxShadow: "none"
  }
};

export default actionButtonsStyle;
