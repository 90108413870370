import React, { Component } from "react";
import PropTypes from "prop-types/prop-types";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

import { withStyles } from "@material-ui/core/styles";
import { infoColor, whiteColor } from "_assets/jss/materialAdmin";

const style = theme => ({
  fab: {
    margin: 0,
    top: "auto",
    left: "auto",
    bottom: 30,
    right: 30,
    position: "fixed",
    backgroundColor: infoColor[0],
    color: whiteColor
  }
});

class FloatingActionButton extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Fab className={classes.fab}>
        <AddIcon />
      </Fab>
    );
  }
}

FloatingActionButton.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(FloatingActionButton);
