import { api } from "services";
import buildUrl from "build-url";
import { CHAT_HISTORY_SIZE, ExternalUrl } from "consts";

const method = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE"
};

export const fetchCitiesAutoComplete = searchText => {
  return api.get(ExternalUrl.CITIES_AUTO_COMPLETE(searchText));
};

export const fetchCompanies = () => {
  return api.get(ExternalUrl.COMPANIES);
};

export const fetchConditions = () => {
  return api.get(ExternalUrl.CONDITIONS);
};

export const fetchPlaceAutoComplete = (searchText, uuid) => {
  return api.get(ExternalUrl.PLACES_AUTO_COMPLETE(searchText, uuid));
};

export const fetchVehicles = () => {
  return api.get(ExternalUrl.VEHICLES);
};

export const fetchVehiclesForCompany = companyId => {
  return api.get(ExternalUrl.VEHICLES_FOR_COMPANY(companyId));
};

export const updateTripVehicle = (tripId, vehicleId) => {
  return _backendRequestMaker(
    method.POST,
    ExternalUrl.UPDATE_TRIP_VEHICLE(tripId),
    { id: vehicleId }
  );
};

export const getChatList = (page, pageSize) => {
  const url = buildUrl(ExternalUrl.CHATS, {
    queryParams: {
      page,
      pageSize
    }
  });
  return _backendRequestMaker(method.GET, url);
};

export const getMoreMessages = async (chatCode, messageId) => {
  const url = buildUrl(ExternalUrl.CHAT_HISTORY(chatCode), {
    queryParams: {
      fromMessageId: messageId,
      historySize: CHAT_HISTORY_SIZE
    }
  });
  return _backendRequestMaker(method.GET, url);
};

export const getReservation = code => {
  return api.get(ExternalUrl.RESERVATION(code));
};

export const getReservations = id => {
  return api.get(ExternalUrl.RESERVATIONS_V2(id));
};

export const getReservationAllowanceDeadline = id => {
  return api.get(ExternalUrl.RESERVATION_ALLOWANCE_DEADLINE(id));
};

export const getPaymentF2FRequest = () => {
  return api.get(ExternalUrl.F2F_PAYMENT_REQUEST);
};

export const hasMessageBefore = async (chatCode, messageId) => {
  const url = buildUrl(ExternalUrl.HAS_MESSAGE_BEFORE(chatCode), {
    queryParams: {
      fromMessageId: messageId
    }
  });
  return _backendRequestMaker(method.GET, url);
};

export const sendChatMessage = (chatCode, messageText) => {
  return _backendRequestMaker(method.POST, ExternalUrl.SEND_CHAT_MESSAGE, {
    chatCode: chatCode,
    value: messageText
  });
};

export const getTripDetails = idTrip => {
  return _backendRequestMaker(method.GET, ExternalUrl.TRIP_DETAILS(idTrip));
};

export const getBoardingPoints = idTrip => {
  return _backendRequestMaker(
    method.GET,
    ExternalUrl.GET_BOARDING_POINTS(idTrip)
  );
};

export const doConfirmTrip = idTrip => {
  return _backendRequestMaker(method.POST, ExternalUrl.CONFIRM_TRIP(idTrip));
};

export const doInformalReservation = (informalPassenger, idTrip) => {
  return _backendRequestMaker(
    method.POST,
    ExternalUrl.INFORMAL_RESERVATION_V2,
    {
      ...informalPassenger,
      trip: {
        id: idTrip
      }
    }
  );
};

export const doCancelTrip = idTrip => {
  return _backendRequestMaker(method.DELETE, ExternalUrl.CANCEL_TRIP(idTrip));
};

export const doCloneTrip = (tripId, cloneTripObject) => {
  return _backendRequestMaker(
    method.POST,
    ExternalUrl.CLONE_TRIP(tripId),
    cloneTripObject
  );
};

export const deleteInformalPassenger = id => {
  return _backendRequestMaker(
    method.DELETE,
    buildUrl(ExternalUrl.RESERVATION_V2_REMOVE(id))
  );
};

export const markNotificationRead = ({ id }) => {
  return api.post(ExternalUrl.MARK_NOTIFICATION_READ(id));
};

const _backendRequestMaker = async (requestMethod, url, body = {}) => {
  switch (requestMethod) {
    case method.GET:
      return await api.get(url);
    case method.POST:
      return await api.post(url, body);
    case method.DELETE:
      return await api.delete(url);
    default:
      return null;
  }
};
