import React, { Component } from "react";
import { connect } from "react-redux";
import VehicleTabs from "views/Vehicle/containers/VehicleTabs";
import VehicleActions from "views/Vehicle/redux/VehicleActions";
import { Messages, ServerStatus } from "consts";
import PropTypes from "prop-types/prop-types";
import { getParamsFromLocation } from "helpers/routeHelper";
import { ErrorOutline } from "@material-ui/icons";
import Snackbar from "components/Snackbar/Snackbar";

class VehicleEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbar: { color: "danger", message: "", open: false }
    };
  }

  componentDidMount() {
    this.props.requestVehicle(this.props.match.params.id);

    // Open snackbar when incoming from success request
    this.handleSnackbar(
      "success",
      Messages.SUCCESS_VEHICLE_SAVED,
      !!getParamsFromLocation(this.props, ServerStatus.REQUEST_SUCCESS, null)
    );

    // Close snackbar after 3 seconds
    setTimeout(() => {
      this.handleSnackbar(this.state.color, "", false);
    }, 3000);
  }

  handleSnackbar = (color, message, open) => {
    this.setState({ snackbar: { color, message, open } });
  };

  onSubmit = formValues => {
    this.props.editVehicle(formValues);
  };

  render() {
    const { color, open, message } = this.state.snackbar;

    return (
      <>
        <Snackbar
          place="tl"
          color={color}
          icon={ErrorOutline}
          message={message}
          open={open}
          closeNotification={() => this.handleSnackbar(color, "", false)}
          close
        />

        <VehicleTabs onSubmit={this.onSubmit} isEdition={true} />
      </>
    );
  }
}

VehicleEdit.propTypes = {
  editVehicle: PropTypes.func,
  requestVehicle: PropTypes.func,
  match: PropTypes.object,
  vehicle: PropTypes.object
};

const mapDispatchToProps = {
  editVehicle: VehicleActions.editVehicle,
  requestVehicle: VehicleActions.requestVehicle
};

export default connect(null, mapDispatchToProps)(VehicleEdit);
