import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { phoneMask } from "helpers/maskHelper";
import Icon from "@material-ui/core/Icon";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import EmailIcon from "@material-ui/icons/Email";

import { getPaymentF2FRequest } from "promises";
import ServerStatus from "consts/ServerStatus";
import { ExternalUrl, Messages } from "consts";
import AlertDialog from "components/AlertDialog/AlertDialog";
import { api as axios } from "services";
import { ErrorOutline } from "@material-ui/icons";
import Snackbar from "../../components/Snackbar/Snackbar";
import ButtonLoading from "../../components/CustomButtons/ButtonLoading";

const styles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [alertController, setAlertController] = useState({
    open: false,
    title: "",
    content: "",
    action: null
  });
  const [snackbarComponent, setSnackbarComponent] = React.useState({
    open: false,
    message: "",
    type: "danger"
  });
  const [loadingApproved, setLoadingApproved] = useState(false);
  const [loadingReproved, setLoadingReproved] = useState(false);
  const classes = styles();

  const handleSnackbar = (open, message, type) => {
    setSnackbarComponent({
      open: open,
      message: message,
      type: type
    });
  };

  function closeSnackbar() {
    handleSnackbar(false, "");
  }

  function clickControl(title, content, code, approved) {
    setAlertController({
      open: true,
      title: title,
      content: content,
      action: () => f2fPayment(code, approved)
    });
  }

  const f2fPayment = (code, approved) => {
    setAlertController({
      open: false
    });

    if (approved) {
      setLoadingApproved(true);
    } else {
      setLoadingReproved(true);
    }

    axios
      .post(ExternalUrl.F2F_PAYMENT_REQUEST, {
        code,
        approved
      })
      .then(data => {
        if (data.status === ServerStatus.SUCCESS) {
          props.getPaymentF2FRequest();
        }

        if (data.status === ServerStatus.INVALID) {
          handleSnackbar(true, data.data, "danger");
        }
      })
      .catch(err => {
        handleSnackbar(true, Messages.ERROR_UNEXPECTED_FROM_SERVER, "danger");
      })
      .finally(() => {
        setLoadingApproved(false);
        setLoadingReproved(false);
        setOpen(false);

        setTimeout(() => {
          handleSnackbar(false, "");
        }, 3000);
      });
  };

  return (
    <React.Fragment>
      <Snackbar
        place="tl"
        color={snackbarComponent.type}
        icon={ErrorOutline}
        message={snackbarComponent.message}
        open={snackbarComponent.open}
        closeNotification={() => closeSnackbar()}
        close
      />
      {alertController.open ? (
        <AlertDialog
          title={alertController.title}
          content={alertController.content}
          handleDialogCancel={() => setAlertController({ open: false })}
          handleDialogConfirm={alertController.action}
        />
      ) : null}
      <TableRow className={classes.root}>
        <TableCell
          style={{ display: "flex", justifyContent: "center", width: "10px" }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" style={{ width: "100%" }}>
          {row.requester.name}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={2}>
          <div
            style={{
              width: "100%",
              display: "flex"
            }}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "10px"
                    }}
                  >
                    <Icon
                      aria-label="Celular"
                      style={{ color: "red", padding: "2px" }}
                    >
                      <PhoneAndroidIcon style={{ color: "#ff9800" }} />
                    </Icon>
                    <span style={{ margin: "4px 5px 0 5px" }}>
                      {phoneMask(row.requester.phone)}
                    </span>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "10px"
                    }}
                  >
                    <Icon
                      aria-label="Celular"
                      style={{ color: "red", padding: "2px" }}
                    >
                      <EmailIcon style={{ color: "#ff9800" }} />
                    </Icon>
                    <span style={{ margin: "4px 5px 0 5px" }}>
                      {row.requester.username}
                    </span>
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div
                    style={{
                      margin: "15px 10px 10px 10px"
                    }}
                  >
                    <ButtonLoading
                      variant="contained"
                      loading={loadingReproved}
                      fullWidth
                      style={{
                        backgroundColor: "red",
                        width: "100%"
                      }}
                      onClick={() =>
                        clickControl(
                          "Pagamento presencial",
                          "Deseja realmente reprovar esse pedido?",
                          row.code,
                          false
                        )
                      }
                    >
                      Reprovar
                    </ButtonLoading>
                    <ButtonLoading
                      variant="contained"
                      loading={loadingApproved}
                      fullWidth
                      style={{
                        backgroundColor: "green",
                        width: "100%"
                      }}
                      onClick={() =>
                        clickControl(
                          "Pagamento presencial",
                          "Deseja realmente aprovar esse pedido?",
                          row.code,
                          true
                        )
                      }
                    >
                      Aprovar
                    </ButtonLoading>
                  </div>
                </GridItem>
              </GridContainer>
            </Collapse>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    code: PropTypes.string,
    createdAt: PropTypes.string,
    requestType: PropTypes.string,
    requester: PropTypes.object
  }),
  getPaymentF2FRequest: PropTypes.func
};

export default function F2FRequest() {
  const [f2fRequests, setF2FRequests] = useState([]);

  useEffect(() => {
    paymentF2FRequests();
  }, [setF2FRequests]);

  function paymentF2FRequests() {
    getPaymentF2FRequest().then(data => {
      if (data.status.toString() === ServerStatus.SUCCESS) {
        setF2FRequests(data.data);
      }
    });
  }

  return (
    <>
      {f2fRequests.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Nome</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {f2fRequests.map(request => {
                return (
                  <Row
                    key={request.requester.id}
                    row={request}
                    getPaymentF2FRequest={paymentF2FRequests}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <p style={{ textAlign: "center" }}>{Messages.EMPTY_F2F_REQUEST}</p>
      )}
    </>
  );
}
