import Cookies from "universal-cookie";
import { history } from "common/config";
import { InternalUrl, TOKEN_KEY } from "consts";
import jwtDecode from "jwt-decode";

const cookies = new Cookies();

export const isAuthenticated = () => getToken();

export const getToken = () => cookies.get(TOKEN_KEY);

export const removeToken = () => cookies.remove(TOKEN_KEY, { path: "/" });

export const logout = () => {
  removeToken();
  history.push(InternalUrl.SIGN_IN);
};

export const signIn = token =>
  cookies.set(TOKEN_KEY, token, { path: "/", secure: true, maxAge: 2592000 }); //this is 1 month

export const getUserRoles = () => jwtDecode(getToken()).roles;
