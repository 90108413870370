import React, { useState } from "react";
import NotificationActions from "common/redux/notifications/NotificationActions";
import Button from "components/CustomButtons/Button";
import classNames from "classnames";
import Notification from "components/Notification/Notification";
import { logout } from "services";
import styles from "_assets/jss/components/headerLinksStyle";

import { useSelector } from "react-redux";
import {
  ClickAwayListener,
  Grow,
  Hidden,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@material-ui/core";

import { Chat as ChatIcon, Notifications, Person } from "@material-ui/icons";
const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const notifications = useSelector(state => state.notifications);
  const [openProfile, setOpenProfile] = useState(null);

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleLogout = () => {
    setOpenProfile(null);
    logout();
  };

  return (
    <div>
      <Notification
        notifications={notifications.chat}
        action={NotificationActions.READ_CHAT_NOTIFICATION}
      >
        <ChatIcon className={classes.icons} />
      </Notification>
      <Notification
        notifications={notifications.reservation}
        action={NotificationActions.READ_RESERVATION_NOTIFICATION}
      >
        <Notifications className={classes.icons} />
      </Notification>
      <Hidden smDown>
        <div className={classes.manager}>
          <Button
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={openProfile ? "profile-menu-list-grow" : null}
            aria-haspopup="true"
            onClick={handleClickProfile}
            //className={classes.buttonLink}
          >
            <Person className={classes.icons} />
          </Button>
          <Popper
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openProfile }) +
              " " +
              classes.popperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper style={{ padding: "5px" }}>
                  <ClickAwayListener onClickAway={() => setOpenProfile(null)}>
                    <MenuList role="menu">
                      <MenuItem
                        onClick={handleLogout}
                        className={classes.menuItem}
                      >
                        Sair
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Hidden>
    </div>
  );
}
