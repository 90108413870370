import React, { Component } from "react";
import PropTypes from "prop-types/prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class AlertDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  handleDialogCancel = () => {
    this.props.handleDialogCancel();
  };

  handleDialogConfirm = () => {
    this.props.handleDialogConfirm();
  };

  render() {
    const { title, content } = this.props;
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleDialogCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleDialogCancel} color="primary">
            cancelar
          </Button>
          <Button onClick={this.handleDialogConfirm} style={{ color: "red" }}>
            confimar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AlertDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  handleDialogCancel: PropTypes.func,
  handleDialogConfirm: PropTypes.func
};

export default AlertDialog;
