import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import vehicle from "views/Vehicle/redux/vehicleReducer";
import trips from "views/Trip/redux/TripReducer";
import notificationReducer from "common/redux/notifications/NotificationReducer";
import error from "common/redux/loading/ErrorReducer";
import loading from "common/redux/loading/LoadingReducer";

export default combineReducers({
  form: formReducer,
  loading,
  error,
  vehicle,
  trips,
  notifications: notificationReducer
});
