import React, { Component } from "react";
import { connect } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import { Link } from "react-router-dom";
import PropTypes from "prop-types/prop-types";
import { withStyles } from "@material-ui/core/styles";
import { ErrorOutline } from "@material-ui/icons";
import ButtonLoading from "components/CustomButtons/ButtonLoading";
import CardFooter from "components/Card/CardFooter";
import SimpleBackdrop from "components/backdrop/Backdrop";
import Snackbar from "components/Snackbar/Snackbar";
import { InternalUrl } from "consts";
import VehicleActions from "views/Vehicle/redux/VehicleActions";
import {
  createErrorMessageSelector,
  createLoadingSelector
} from "common/redux/loading/selectors";

const styles = () => ({
  dropzone: {
    margin: "0"
  },
  vehicleImage: {
    "@media only screen and (min-width: 959px)": {
      width: "618px",
      height: "350px",
      margin: "auto"
    }
  }
});

class VehiclePhoto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      isEditClicked: false,
      emptyFile: false,
      snackbar: { color: "danger", message: "", open: false }
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { snackbar } = this.state;
    const { failureToSavePhoto } = this.props;

    if (failureToSavePhoto && !snackbar.open) {
      this.handleSnackbar("danger", failureToSavePhoto, true);
      this.props.clearError(VehicleActions.SAVE_PHOTO_CLEAR_FAILURE);

      // Close snackbar after 3 seconds
      setTimeout(() => {
        this.handleSnackbar(this.state.color, "", false);
      }, 3000);
    }
  }

  handleSnackbar = (color, message, open) => {
    this.setState({ snackbar: { color, message, open } });
  };

  handleChange = files => {
    this.setState({
      files: files,
      emptyFile: false
    });
  };

  onSave = isVehiclePhotoToEdit => {
    if (isVehiclePhotoToEdit && !this.state.isEditClicked) {
      this.setState({ isEditClicked: true });
    } else {
      if (this.state.files[0]) {
        this.props.savePhoto(this.state.files[0], this.props.vehicle.id);
        this.setState({ isEditClicked: false, emptyFile: false });
      } else {
        this.setState({ emptyFile: true });
      }
    }
  };

  // TODO: better solution for dropzone
  // https://github.com/react-dropzone/react-dropzone
  render() {
    const { classes, isFetching, pristine, submitting, vehicle } = this.props;
    const isVehiclePhotoToEdit = Boolean(vehicle && vehicle.photo);
    const { color, open, message } = this.state.snackbar;

    return (
      <>
        <Snackbar
          place="tl"
          color={color}
          icon={ErrorOutline}
          message={message}
          open={open}
          closeNotification={() => this.handleSnackbar(color, "", false)}
          close
        />

        <SimpleBackdrop open={isFetching} />
        <div className={classes.dropzone}>
          <div>
            {isVehiclePhotoToEdit && !this.state.isEditClicked ? (
              <div className={classes.vehicleImage}>
                <img
                  src={vehicle.photo}
                  style={{ width: "100%" }}
                  alt={"Foto do veículo"}
                />
              </div>
            ) : (
              <>
                {this.state.emptyFile ? (
                  <p style={{ textAlign: "center", color: "red", margin: 0 }}>
                    Selecione uma foto
                  </p>
                ) : null}
                <DropzoneArea
                  filesLimit={1}
                  // maxFileSize={}
                  acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
                  dropzoneText="Clique aqui para selecionar a imagem"
                  onChange={this.handleChange}
                  showAlerts={false}
                  clearOnUnmount={true}
                />
              </>
            )}
          </div>
        </div>
        <CardFooter>
          <Link to={InternalUrl.VEHICLES.LIST}>
            <ButtonLoading
              variant="contained"
              disabled={pristine || submitting}
              color={"secondary"}
              style={{
                width: "120px",
                marginRight: 5
              }}
            >
              Cancelar
            </ButtonLoading>
          </Link>
          <ButtonLoading
            type="submit"
            variant="contained"
            loading={false}
            disabled={pristine || submitting}
            onClick={() => this.onSave(isVehiclePhotoToEdit)}
            style={{ width: "120px" }}
          >
            {isVehiclePhotoToEdit && !this.state.isEditClicked
              ? "Editar"
              : "Salvar"}
          </ButtonLoading>
        </CardFooter>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([VehicleActions.SAVE_PHOTO]);
const errorSavePhoto = createErrorMessageSelector([VehicleActions.SAVE_PHOTO]);

VehiclePhoto.propTypes = {
  classes: PropTypes.object,
  pristine: PropTypes.bool,
  savePhoto: PropTypes.func,
  submitting: PropTypes.bool,
  vehicle: PropTypes.object,
  isFetching: PropTypes.bool,
  clearError: PropTypes.func,
  failureToSavePhoto: PropTypes.string
};

const mapStateToProps = state => ({
  vehicle: state.vehicle.vehicle.data,
  isFetching: loadingSelector(state),
  failureToSavePhoto: errorSavePhoto(state)
});

const mapDispatchToProps = {
  savePhoto: VehicleActions.savePhoto,
  clearError: VehicleActions.clearError
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(VehiclePhoto));
