import React, { Component } from "react";
import { connect } from "react-redux";
import VehicleTabs from "views/Vehicle/containers/VehicleTabs";
import VehicleActions from "views/Vehicle/redux/VehicleActions";
import PropTypes from "prop-types/prop-types";

class VehicleNew extends Component {
  onSubmit = formValues => {
    this.props.saveVehicle(formValues);
  };

  render() {
    return (
      <>
        <VehicleTabs onSubmit={this.onSubmit} isEdition={false} />
      </>
    );
  }
}

VehicleNew.propTypes = {
  saveVehicle: PropTypes.func,
  vehicle: PropTypes.object
};

const mapStateToProps = state => ({
  vehicle: state.vehicle.vehicle
});

export default connect(mapStateToProps, {
  saveVehicle: VehicleActions.saveVehicle
})(VehicleNew);
