import { grayColor } from "_assets/jss/materialAdmin";

const autoCompleteStyle = {
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: "relative"
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: "10px",
    left: 0,
    right: 0
  },
  suggestion: {
    display: "block"
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none"
  },
  icon: {
    color: grayColor[0],
    marginRight: "2px"
  }
};

export default autoCompleteStyle;
