import {
  defaultFont,
  dangerColor,
  whiteColor,
  primaryColor,
  grayColor,
  primaryBoxShadow,
  infoColor
} from "_assets/jss/materialAdmin";

const headerLinksStyle = theme => ({
  search: {
    "& > div": {
      marginTop: "0"
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 15px !important",
      float: "none !important",
      paddingTop: "1px",
      paddingBottom: "1px",
      padding: "0!important",
      width: "60%",
      marginTop: "40px",
      "& input": {
        color: whiteColor
      }
    }
  },
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0px"
  },
  buttonLink: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "10px 15px 0",
      width: "-webkit-fill-available",
      "& svg": {
        width: "24px",
        height: "30px",
        marginRight: "15px",
        marginLeft: "-15px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "24px",
        lineHeight: "30px",
        width: "24px",
        height: "30px",
        marginRight: "15px",
        marginLeft: "-15px"
      },
      "& > span": {
        justifyContent: "flex-start",
        width: "100%"
      }
    }
  },
  searchButton: {
    [theme.breakpoints.down("sm")]: {
      top: "-50px !important",
      marginRight: "22px",
      float: "right"
    }
  },
  margin: {
    zIndex: "4",
    margin: "0"
  },
  searchIcon: {
    width: "17px",
    zIndex: "4"
  },
  notifications: {
    zIndex: "4",
    // [theme.breakpoints.up("md")]: {
    position: "absolute",
    top: "2px",
    border: "1px solid " + whiteColor,
    right: "4px",
    fontSize: "9px",
    background: dangerColor[0],
    color: whiteColor,
    minWidth: "16px",
    height: "16px",
    borderRadius: "10px",
    textAlign: "center",
    lineHeight: "16px",
    verticalAlign: "middle",
    display: "block"
    // },
    // [theme.breakpoints.down("sm")]: {
    //   ...defaultFont,
    //   fontSize: "14px",
    //   marginRight: "8px"
    // }
  },
  manager: {
    // [theme.breakpoints.down("sm")]: {
    //   width: "100%"
    // },
    display: "inline-block"
  },
  searchWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0"
    },
    display: "inline-block"
  },
  actionButton: {
    padding: "0",
    marginLeft: "4px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0"
    }
  },
  closeIcon: {
    backgroundColor: "transparent",
    color: dangerColor[0],
    boxShadow: "none",
    width: "22px",
    height: "22px",
    margin: "0 8px 0 8px",
    [theme.breakpoints.down("sm")]: {
      width: "20px",
      height: "20px"
    }
  },
  notificationPaper: {
    padding: "5px",
    maxWidth: "94vw"
  },
  notificationMenuList: {
    padding: "0"
  },
  notificationContentWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  notificationTime: {
    fontSize: "10px",
    color: infoColor[0]
  },
  notificationText: {
    overflowX: "hidden",
    textOverflow: "ellipsis"
  },
  menuItemWrapper: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ccc",
    "&:first-child": {
      borderTop: "none"
    }
  },
  menuItem: {
    ...defaultFont,
    fontSize: "16px",
    padding: "5px 5px",
    //margin: "5px",
    borderRadius: "2px",
    WebkitTransition: "all 150ms linear",
    MozTransition: "all 150ms linear",
    OTransition: "all 150ms linear",
    MsTransition: "all 150ms linear",
    transition: "all 150ms linear",
    display: "block",
    clear: "both",
    fontWeight: "400",
    lineHeight: "1.42857143",
    color: grayColor[8],
    whiteSpace: "nowrap",
    height: "unset",
    minHeight: "unset",
    width: "100%",
    "&:hover": {
      width: "100%",
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...primaryBoxShadow
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  }
});

export default headerLinksStyle;
