import React, { Component } from "react";
import { Field } from "redux-form";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import DateTime from "components/DateTime/DateTime";
import ErrorHelperText, {
  isInputError,
  isInputSuccess
} from "components/Error/ErrorHelperText";
import PlaceAutoComplete from "components/AutoComplete/PlaceAutoComplete";

class TripLocationForm extends Component {
  requiredValidator = value => (value ? undefined : "Campo obrigatório");

  departurePlaceValidator = (value, formValues) => {
    return value && !formValues.departure ? "Selecione uma cidade" : undefined;
  };

  arrivalPlaceValidator = (value, formValues) => {
    return value && !formValues.arrival ? "Selecione uma cidade" : undefined;
  };

  renderPlaceAutoComplete = props => {
    const { meta } = props;
    return (
      <PlaceAutoComplete
        {...props}
        error={isInputError(meta)}
        success={isInputSuccess(meta)}
      >
        <ErrorHelperText meta={meta} />
      </PlaceAutoComplete>
    );
  };

  renderDateTimePicker = props => {
    const { meta } = props;
    return (
      <DateTime
        {...props}
        error={isInputError(meta)}
        success={isInputSuccess(meta)}
      >
        <ErrorHelperText meta={meta} />
      </DateTime>
    );
  };

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Field
              component={props => <input {...props.input} type="hidden" />}
              name="departure"
              type="text"
            />
            <Field
              component={this.renderPlaceAutoComplete}
              labelText="Saindo de"
              placeholder="Digite o local de partida"
              name="departureName"
              field={"departure"}
              type="text"
              validate={[this.requiredValidator, this.departurePlaceValidator]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Field
              component={this.renderDateTimePicker}
              name="departureTime"
              placeholder="Data e hora"
              type="text"
              validate={this.requiredValidator}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            <Field
              component={props => <input {...props.input} type="hidden" />}
              name="arrival"
              type="text"
            />
            <Field
              component={this.renderPlaceAutoComplete}
              labelText="Indo para"
              name="arrivalName"
              field={"arrival"}
              placeholder="Digite o local de chegada"
              type="text"
              validate={[this.requiredValidator, this.arrivalPlaceValidator]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Field
              component={this.renderDateTimePicker}
              name="arrivalTime"
              placeholder="Data e hora"
              type="text"
              validate={this.requiredValidator}
            />
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default TripLocationForm;
