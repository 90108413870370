import React from "react";

import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";

import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  green,
  indigo,
  lime,
  orange,
  pink,
  purple,
  red,
  teal
} from "@material-ui/core/colors";

import { InternalUrl } from "consts";
import { calculateRelativeTimeFromPast } from "helpers/dateTimeHelper";
import { makeStyles } from "@material-ui/core/styles";

import { history } from "common/config";

import PropTypes from "prop-types/prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px"
  },
  inline: {
    display: "inline"
  },
  relativeTime: {
    float: "right",
    color: blue[500],
    fontSize: "12px"
  }
}));

export default function ItemChat(props) {
  const classes = useStyles();
  const { chat } = props;
  const colors = [
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    green,
    indigo,
    lime,
    orange,
    pink,
    purple,
    red,
    teal
  ];

  function getAvatarColor() {
    return colors[Math.floor(Math.random() * 13)][500];
  }

  return (
    <>
      <div className={classes.root}>
        <Typography variant="body2" color="textSecondary" component="p">
          {chat.departure.substring(0, chat.departure.length - 4)}
        </Typography>
        <ArrowRightAltIcon style={{ margin: "0 10px 0 10px" }} />
        <Typography variant="body2" color="textSecondary" component="p">
          {chat.arrival.substring(0, chat.arrival.length - 4)}
        </Typography>
      </div>
      <ListItem
        onClick={() => history.push(InternalUrl.CHAT_MESSAGE(chat.code))}
      >
        <ListItemAvatar>
          <Avatar alt="Nome" style={{ backgroundColor: getAvatarColor() }}>
            {chat.recipient.substr(0, 1)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={chat.recipient}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textSecondary"
              >
                {chat.lastMessage.length <= 10
                  ? chat.lastMessage
                  : chat.lastMessage.substr(0, 10) + "..."}
              </Typography>
              <span className={classes.relativeTime}>
                {calculateRelativeTimeFromPast(chat.sentAt)}
              </span>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </>
  );
}

ItemChat.propTypes = {
  chat: PropTypes.object
};
