import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types/prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Pagination from "material-ui-flat-pagination";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Hidden from "@material-ui/core/Hidden";
import {
  InternalUrl,
  Messages,
  ServerStatus,
  TRIPS_COMPANY_PAGE_SIZE
} from "consts";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button";
import Can from "components/Rules/Can";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardTrip from "views/Trip/components/CardTrip";
import CardFooter from "components/Card/CardFooter";
import FloatingActionButton from "components/FloatingActionButton/FloatingActionButton";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table";
import styles from "_assets/jss/components/buttonStyle";
import { withStyles } from "@material-ui/core/styles";
import { formatDatetime } from "helpers/dateTimeHelper";
import { ErrorOutline } from "@material-ui/icons";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import {
  createErrorMessageSelector,
  createLoadingSelector
} from "common/redux/loading/selectors";
import Skeletons from "components/Skeleton/Skeletons";
import Snackbar from "components/Snackbar/Snackbar";
import { getParamsFromLocation } from "helpers/routeHelper";
import TripActions from "views/Trip/redux/TripActions";

const theme = createMuiTheme();

class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPast: false,
      checked: false,
      page: 0,
      offset: 0,
      pageSize: TRIPS_COMPANY_PAGE_SIZE,
      snackbar: { color: "danger", message: "", open: false }
    };
  }

  componentDidMount() {
    // Open snackbar when incoming from success request
    this.handleSnackbar(
      "success",
      Messages.SUCCESS_TRIP_SAVED,
      getParamsFromLocation(this.props, ServerStatus.REQUEST_SUCCESS, false)
    );

    // Close snackbar after 3 seconds
    setTimeout(() => {
      this.handleSnackbar(this.state.color, "", false);
    }, 3000);

    const { requestTripsPagination } = this.props;

    requestTripsPagination(
      this.state.page,
      this.state.pageSize,
      this.state.showPast
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { snackbar } = this.state;
    const { failureToGet } = this.props;

    if (failureToGet && !snackbar.open) {
      this.handleSnackbar("danger", failureToGet, true);
      this.props.clearError(TripActions.GET_TRIPS_CLEAR_FAILURE);
    }
  }

  handleSnackbar = (color, message, open) => {
    this.setState({ snackbar: { color, message, open } });
  };

  renderTripsDesktop = data => {
    // TODO: loading skeleton for desktop

    const trips = [];
    if (data) {
      data.trips.map(trip => {
        return trips.push([
          trip.departure,
          formatDatetime(trip.departureTime),
          trip.arrival,
          trip.reservations
        ]);
      });
    }

    return (
      <Table
        tableHeaderColor="warning"
        tableHead={[
          "Saindo de",
          "Data e Hora",
          "Indo para",
          "Quantidade de Reservas"
        ]}
        tableData={trips}
      />
    );
  };

  renderTripsMobile = data => {
    if (this.props.isFetching) {
      return <Skeletons amount={4} height={228} maxWidth={345} />;
    }

    return data && data.trips.length > 0 ? (
      data.trips.map((trip, index) => {
        return (
          <div key={index}>
            <CardTrip trip={trip} />
          </div>
        );
      })
    ) : this.props.failureToGet ? null : (
      <p style={{ textAlign: "center" }}>{Messages.EMPTY_TRIPS_LIST}</p>
    );
  };

  renderShowPastCheckbox = () => {
    const { checked } = this.state;
    return (
      <FormControlLabel
        style={{ margin: "0 0 10px 0" }}
        control={
          <Checkbox checked={checked} onChange={this.onChangeShowPast} />
        }
        label="Mostrar viagens passadas"
      />
    );
  };

  onChangeShowPast = event => {
    const { clearTripRequest, requestTripsPagination } = this.props;
    const checked = event.target.checked;

    this.setState({
      checked,
      showPast: event.target.checked,
      offset: 0
    });

    clearTripRequest();
    requestTripsPagination(0, this.state.pageSize, checked);
  };

  fetchTripPagination = (e, offset, page) => {
    const { clearTripRequest, requestTripsPagination } = this.props;
    const { pageSize, showPast } = this.state;
    this.setState({ offset });
    clearTripRequest();
    requestTripsPagination(page - 1, pageSize, showPast);
  };

  render() {
    const { classes } = this.props;
    const { data } = this.props.trips;
    const { pageSize, offset } = this.state;
    const { color, open, message } = this.state.snackbar;

    return (
      <>
        <Snackbar
          place="tl"
          color={color}
          icon={ErrorOutline}
          message={message}
          open={open}
          closeNotification={() => this.handleSnackbar(color, "", false)}
          close
        />

        <Hidden xsDown implementation="css">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary" stats icon>
                  <Hidden xsDown implementation="css">
                    <Can
                      perform={"trip:create"}
                      yes={() => (
                        <Link to={InternalUrl.TRIPS.NEW}>
                          <Button className={classes.addButton}>
                            Adicionar
                          </Button>
                        </Link>
                      )}
                      no={() => null}
                    />
                  </Hidden>
                </CardHeader>
                {this.renderShowPastCheckbox()}
                <CardBody>{this.renderTripsDesktop(data)}</CardBody>
                <CardFooter />
              </Card>
            </GridItem>
          </GridContainer>
        </Hidden>

        <Hidden smUp>
          {this.renderShowPastCheckbox()}
          {this.renderTripsMobile(data)}
          <Can
            perform={"trip:create"}
            yes={() => (
              <Link to={InternalUrl.TRIPS.NEW}>
                <FloatingActionButton />
              </Link>
            )}
            no={() => null}
          />
        </Hidden>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Pagination
              limit={pageSize}
              offset={offset}
              total={data ? data.totalElements : 0}
              onClick={this.fetchTripPagination}
            />
          </MuiThemeProvider>
        </div>
      </>
    );
  }
}

const loadingSelector = createLoadingSelector([TripActions.GET_TRIPS]);
const errorGet = createErrorMessageSelector([TripActions.GET_TRIPS]);

const mapStateToProps = state => ({
  isFetching: loadingSelector(state),
  failureToGet: errorGet(state),
  trips: state.trips.trips
});

Trips.propTypes = {
  classes: PropTypes.object,
  clearError: PropTypes.func,
  isFetching: PropTypes.bool,
  failureToGet: PropTypes.string,
  requestTripsPagination: PropTypes.func,
  clearTripRequest: PropTypes.func,
  totalElements: PropTypes.number,
  trips: PropTypes.any
};

export default connect(mapStateToProps, {
  requestTripsPagination: TripActions.requestTripsPagination,
  clearError: TripActions.clearError,
  clearTripRequest: TripActions.clearTripRequest
})(withStyles(styles)(Trips));
