import React, { useEffect, useState } from "react";
import { formatDatetime } from "helpers/dateTimeHelper";
import { history } from "common/config";
import { InternalUrl } from "consts";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types/prop-types";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardActionArea from "@material-ui/core/CardActionArea";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import FlipToFrontIcon from "@material-ui/icons/FlipToFront";
import PassengersListPDF from "pdf/PassengersListPDF";

import Can from "components/Rules/Can";
import { isDatePast } from "helpers/dateTimeHelper";
import GetAppIcon from "@material-ui/icons/GetApp";
import ListIcon from "@material-ui/icons/List";
import ServerStatus from "consts/ServerStatus";
import { getReservations } from "promises";

import {
  dangerColor,
  infoColor,
  successColor
} from "_assets/jss/materialAdmin";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    marginBottom: "20px"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  plannedTrip: {
    color: infoColor[0],
    fontWeight: "bold"
  },
  confirmedTrip: {
    color: successColor[0],
    fontWeight: "bold"
  },
  cancelledTrip: {
    color: dangerColor[0],
    fontWeight: "bold"
  },
  oldTrip: {
    color: "gray",
    fontWeight: "bold",
    textDecoration: "line-through gray"
  }
}));

const getTripStatus = trip => {
  switch (trip.status) {
    case "SCHEDULED":
      return "AGENDADA";
    case "CONFIRMED":
      return "CONFIRMADA";
    case "CANCELLED":
      return "CANCELADA";
    default:
      return "";
  }
};

const getTripStatusClass = trip => {
  if (isDatePast(trip.departureTime)) {
    return "oldTrip";
  }

  switch (trip.status) {
    case "SCHEDULED":
      return "plannedTrip";
    case "CONFIRMED":
      return "confirmedTrip";
    case "CANCELLED":
      return "cancelledTrip";
    default:
      return "";
  }
};

export default function CardTrip(props) {
  const classes = useStyles();
  const { trip } = props;
  const [expanded, setExpanded] = useState(false);
  const [isDownloadPDF, setDownloadPDF] = useState(false);
  const [isDownloadPDFLightList, setDownloadPDFLightList] = useState(false);
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    if (trip.id) {
      getReservations(trip.id).then(data => {
        if (data.status.toString() === ServerStatus.SUCCESS) {
          setReservations(data.data);
        }
      });
    }
  }, [isDownloadPDF, isDownloadPDFLightList, trip]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.card}>
      <CardActionArea
        onClick={() => history.push(InternalUrl.TRIPS.RESERVATIONS(trip.id))}
      >
        <CardContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes[getTripStatusClass(trip)]}
            >
              {getTripStatus(trip)}
            </Typography>
            <Can
              perform={"trip:view-price"}
              yes={() => (
                <Typography variant="body2" color="textSecondary" component="p">
                  {(trip.price * trip.reservedSeats).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                  })}
                </Typography>
              )}
              no={() => null}
            />
          </div>

          <Divider style={{ margin: "10px 0 10px 0" }} />

          <Typography gutterBottom variant="h6" component="h2">
            {formatDatetime(trip.departureTime)}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "35px"
            }}
          >
            <Typography variant="body2" color="textSecondary" component="p">
              {trip.departure.substr(0, trip.departure.length - 4)}
            </Typography>
            <ArrowRightAltIcon style={{ margin: "0 10px 0 10px" }} />
            <Typography variant="body2" color="textSecondary" component="p">
              {trip.arrival.substr(0, trip.arrival.length - 4)}
            </Typography>
          </div>
        </CardContent>
      </CardActionArea>

      <CardActions disableSpacing>
        <IconButton
          aria-label="Assentos reservados"
          style={{ color: "red", padding: "2px" }}
        >
          <PersonIcon style={{ color: "#66bb6a" }} />
        </IconButton>
        <span style={{ margin: "4px 5px 0 5px" }}>
          {trip.reservedSeats ? trip.reservedSeats : 0}
        </span>

        {isDownloadPDF ? (
          <div id="downloadPDFDiv">
            <PassengersListPDF
              trip={trip}
              reservations={reservations}
              onCallback={() => {
                setDownloadPDF(false);
              }}
            />
          </div>
        ) : Array.isArray(reservations) ? null : (
          <IconButton
            aria-label="Lista de passageiros"
            style={{ marginTop: "4px", width: "50px", height: "20px" }}
            onClick={() => setDownloadPDF(true)}
          >
            <GetAppIcon style={{ color: "#F1A203" }} />
          </IconButton>
        )}

        {isDownloadPDFLightList ? (
          <div id="downloadPDFDiv">
            <PassengersListPDF
              trip={trip}
              reservations={reservations}
              isLightList={true}
              onCallback={() => {
                setDownloadPDFLightList(false);
              }}
            />
          </div>
        ) : Array.isArray(reservations) ? null : (
          <IconButton
            aria-label="Lista de passageiros"
            style={{ marginTop: "4px", width: "50px", height: "20px" }}
            onClick={() => setDownloadPDFLightList(true)}
          >
            <ListIcon style={{ color: "#3374FF" }} />
          </IconButton>
        )}

        {isDatePast(trip.departureTime) ? null : (
          <Can
            perform={"trip:manage"}
            yes={() =>
              trip.status === "CANCELLED" ? null : (
                <Link to={InternalUrl.MANAGE_TRIP(trip.id)}>
                  <IconButton
                    aria-label="Gerenciar"
                    style={{ marginTop: "4px", width: "50px", height: "20px" }}
                  >
                    <SettingsIcon style={{ color: "red" }} />
                  </IconButton>
                </Link>
              )
            }
            no={() => null}
          />
        )}

        <Can
          perform={"trip:clone"}
          yes={() => (
            <Link to={InternalUrl.CLONE_TRIP(trip.id)}>
              <IconButton
                aria-label="Clonar"
                style={{ marginTop: "4px", width: "50px", height: "20px" }}
              >
                <FlipToFrontIcon style={{ color: "black" }} />
              </IconButton>
            </Link>
          )}
          no={() => null}
        />

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="detalhes"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <List>
            <ListItem>
              <ListItemAvatar>
                <AirportShuttleIcon />
              </ListItemAvatar>
              <ListItemText
                primary={trip.vehicle}
                secondary={trip.plateNumber}
              />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowForwardIcon />
              </ListItemAvatar>
              <ListItemText secondary={trip.departureFullName} />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <ArrowBackIcon />
              </ListItemAvatar>
              <ListItemText secondary={trip.arrivalFullName} />
            </ListItem>
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
}

CardTrip.propTypes = {
  trip: PropTypes.object,
  departure: PropTypes.string,
  arrival: PropTypes.string,
  reservations: PropTypes.string,
  departureFullName: PropTypes.string,
  arrivalFullName: PropTypes.string
};
