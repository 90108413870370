import React, { useEffect, useState } from "react";
import ItemChat from "views/Chat/components/ItemChat";
import { getChatList } from "promises/promises";
import Skeletons from "components/Skeleton/Skeletons";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import {
  createMuiTheme,
  makeStyles,
  MuiThemeProvider
} from "@material-ui/core/styles";
import PropTypes from "prop-types/prop-types";
import ServerStatus from "consts/ServerStatus";
import { CHATS_PAGE_SIZE, Messages } from "consts";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Pagination from "material-ui-flat-pagination/lib/Pagination";

const theme = createMuiTheme();

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function Chats() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [chats, setChats] = useState([]);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    setFetching(true);
    getChatList(page, CHATS_PAGE_SIZE).then(data => {
      if (data.status === ServerStatus.SUCCESS) {
        setChats(data.data.chats);
        setPage(data.data.page);
        setTotalElements(data.data.totalElements);
      }
      setFetching(false);
    });
  }, []);

  return (
    <>
      <List className={classes.root}>
        {chats.length > 0 ? (
          <Divider variant="fullWidth" component="li" />
        ) : null}
        {isFetching ? (
          <Skeletons
            amount={8}
            height={96}
            maxWidth={345}
            marginBottom={"2px"}
          />
        ) : chats.length === 0 ? (
          <p style={{ textAlign: "center" }}>{Messages.EMPTY_CHATS_LIST}</p>
        ) : (
          chats.map((item, index) => {
            return <ItemChat key={index} chat={item} />;
          })
        )}
      </List>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <Pagination
            limit={CHATS_PAGE_SIZE}
            offset={page * CHATS_PAGE_SIZE}
            total={totalElements}
            onClick={(a, b, newPage) => {
              console.log("go to page", newPage);
              setFetching(true);
              getChatList(newPage - 1, CHATS_PAGE_SIZE).then(data => {
                if (data.status === ServerStatus.SUCCESS) {
                  setChats(data.data.chats);
                  setPage(data.data.page);
                  setTotalElements(data.data.totalElements);
                }
                setFetching(false);
              });
            }}
          />
        </MuiThemeProvider>
      </div>
    </>
  );
}

Chats.propTypes = {
  chats: PropTypes.object
};
