import React from "react";
import { InternalUrl } from "consts";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types/prop-types";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import Can from "components/Rules/Can";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { dangerColor, infoColor } from "_assets/jss/materialAdmin";

import noImageFound from "_assets/img/noImageFound.png";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345,
    marginBottom: "20px"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  content: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

export default function CardList(props) {
  const classes = useStyles();
  const { vehicle } = props;

  return (
    <Card className={classes.card}>
      <CardContent>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Typography
            variant="body1"
            color="primary"
            component="p"
            style={{ fontWeight: "bold" }}
          >
            {vehicle.company.name}
          </Typography>
        </div>
        <Divider style={{ margin: "10px 0 15px 0" }} />
        <CardMedia
          component="img"
          alt="Foto do veículo"
          height="140"
          image={vehicle.photo ? vehicle.photo : noImageFound}
          title="Foto do veículo"
          style={{ borderRadius: "5px", marginBottom: "15px" }}
        />
        <Typography
          variant="caption"
          color="textPrimary"
          component="h1"
          style={{ fontWeight: "bold" }}
        >
          {vehicle.model.toUpperCase()}
        </Typography>
        <div className={classes.content}>
          <div>
            <Typography variant="caption" color="textPrimary" component="p">
              {vehicle.plateNumber}
            </Typography>
          </div>

          <div>
            <Can
              perform={"vehicle:delete"}
              yes={() => (
                <Link to={`${InternalUrl.VEHICLES.REMOVE}/${vehicle.id}`}>
                  <IconButton
                    aria-label="remover veículo"
                    style={{
                      color: dangerColor[0],
                      padding: 0,
                      marginRight: "8px"
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Link>
              )}
              no={() => null}
            />
            <Can
              perform={"vehicle:edit"}
              yes={() => (
                <Link to={`${InternalUrl.VEHICLES.EDIT}/${vehicle.id}`}>
                  <IconButton
                    aria-label="editar veículo"
                    style={{ color: infoColor[0], padding: 0 }}
                  >
                    <EditIcon />
                  </IconButton>
                </Link>
              )}
              no={() => null}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

CardList.propTypes = {
  children: PropTypes.node,
  model: PropTypes.string,
  photo: PropTypes.string,
  vehicle: PropTypes.object
};
