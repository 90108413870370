import React, { Component } from "react";
import { connect } from "react-redux";
import CustomInput from "components/CustomInput/CustomInput";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import parse from "autosuggest-highlight/parse";
import { uuidv4 } from "helpers/uuidGenerator";
import { debounce } from "throttle-debounce";
import { withStyles } from "@material-ui/core/styles";
import { fetchPlaceAutoComplete } from "promises/promises";
import { change } from "redux-form";
import { infoColor } from "_assets/jss/materialAdmin";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types/prop-types";
import Autosuggest from "react-autosuggest";

import styles from "_assets/jss/components/AutoCompleteStyle";

class PlaceAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      suggestions: [],
      uuid: ""
    };
    this.fetchPlaces = debounce(500, this.autocompleteSearch);
  }

  autocompleteSearch = value => {
    fetchPlaceAutoComplete(value, this.state.uuid).then(response => {
      const data = response.data;
      this.setState({
        suggestions: data
      });
    });
  };

  renderSuggestion = props => {
    this.props.setId(this.props.field, "");
    const matches = props.formatting.mainTextMatchedSubstrings;
    const parts = parse(
      props.formatting.mainText,
      matches.map(match => [match.offset, match.offset + match.length])
    );

    return (
      <Grid container alignItems="center">
        <Grid item>
          <LocationOnIcon className={this.props.classes.icon} />
        </Grid>
        <Grid item xs>
          {parts.map((part, index) => (
            <span
              key={index}
              style={{
                color: part.highlight ? infoColor[1] : null,
                fontWeight: part.highlight ? "bold" : null
              }}
            >
              {part.text}
            </span>
          ))}

          <Typography variant="body2" color="textSecondary">
            {props.formatting.secondaryText}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  getSuggestionValue = suggestion => suggestion.fullName;

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    if (this.state.uuid === "") {
      this.setState({ uuid: uuidv4() });
    }
    if (this.state.uuid !== "") {
      this.fetchPlaces(value);
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, method }) => {
    const { input, field } = this.props;
    const { id } = suggestion;
    input.onChange(suggestionValue);
    this.props.setId(field, id);
    this.setState({ uuid: "" });
    if (method === "enter") {
      event.preventDefault();
    }
  };

  renderCustomInput = inputProps => {
    const {
      children,
      error,
      field,
      labelText,
      placeholder,
      success
    } = this.props;
    return (
      <>
        <CustomInput
          id={field}
          labelText={labelText}
          placeholder={placeholder}
          formControlProps={{
            fullWidth: true
          }}
          inputProps={inputProps}
          required={true}
          type={"text"}
          error={error}
          success={success}
        />
        {children}
      </>
    );
  };

  render() {
    const { classes, input } = this.props;
    const { suggestions } = this.state;

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderCustomInput}
        inputProps={input}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    );
  }
}

PlaceAutoComplete.propTypes = {
  setId: PropTypes.func,
  children: PropTypes.node,
  error: PropTypes.bool,
  success: PropTypes.bool,
  input: PropTypes.object,
  classes: PropTypes.object,
  labelText: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  setId: (field, id) => {
    dispatch(change("TripForm", field, id));
  }
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(PlaceAutoComplete));
