export default (state = {}, { type, payload }) => {
  const matches = /(.*)_(REQUEST|FAILURE|CLEARFAILURE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  if (requestState === "CLEARFAILURE") {
    return {
      ...state,
      [requestName]: ""
    };
  }

  return {
    ...state,
    [requestName]: requestState === "FAILURE" ? payload : ""
  };
};
