import React, { Component } from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types/prop-types";
import BrlCurrencyComponent from "components/InputMask/BrlCurrencyComponent";
import CustomInput from "components/CustomInput/CustomInput";
import ErrorHelperText, {
  isInputError,
  isInputSuccess
} from "components/Error/ErrorHelperText";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import InputMask from "components/InputMask/InputMask";

class TripDetailsForm extends Component {
  requiredValidator = value => (value ? undefined : "Campo obrigatório");

  renderCustomInput = props => {
    const { input, labelText, meta, type, placeholder, renderMask } = props;

    if (input.name === "price") {
      const valueWithoutFormat = input.value.replace(/[^0-9]/g, "");

      if (!valueWithoutFormat || valueWithoutFormat === "000") {
        input.onChange("");
      } else if (valueWithoutFormat) {
        const valueWithFormat = (valueWithoutFormat / 100).toLocaleString(
          "pt-BR",
          {
            style: "currency",
            currency: "BRL"
          }
        );
        input.onChange(valueWithFormat);
      }
    }

    return (
      <>
        <CustomInput
          id={input.name}
          inputProps={input}
          inputComponent={renderMask}
          labelText={labelText}
          type={type}
          placeholder={placeholder}
          autoComplete="off"
          required={true}
          formControlProps={{
            fullWidth: true
          }}
          error={isInputError(meta)}
          success={isInputSuccess(meta)}
        />
        <ErrorHelperText meta={meta} />
      </>
    );
  };

  renderMask = props => <InputMask {...props} />;

  renderCurrencyMask = props => <BrlCurrencyComponent {...props} />;

  minimumSeatsToDepartValidator = value => {
    const seats = this.props.travelVehicleSelected.seats;
    if (value > seats) {
      return `O veículo selecionado possui ${seats} assentos`;
    }
    if (value < 2) {
      return "Lotação mínima inválida";
    }
    return undefined;
  };

  reservedSeatsValidator = value => {
    const seats = this.props.travelVehicleSelected.seats;
    if (value > seats) {
      return `O veículo selecionado possui ${seats} assentos`;
    }
    return undefined;
  };

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <Field
              component={this.renderCustomInput}
              labelText="Lotação mínima"
              name="minimumSeatsToDepart"
              placeholder="Digite o lotação mínima"
              type="text"
              renderMask={this.renderMask}
              validate={[
                this.requiredValidator,
                this.minimumSeatsToDepartValidator
              ]}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Field
              component={this.renderCustomInput}
              labelText="Assentos reservados"
              name="reservedSeats"
              placeholder="Digite o número de assentos reservados"
              type="text"
              renderMask={this.renderMask}
              validate={[this.requiredValidator, this.reservedSeatsValidator]}
            />
          </GridItem>
          {/*https://codesandbox.io/s/react-currency-input-with-react-text-mask-4es23*/}
          <GridItem xs={12} sm={12} md={4}>
            <Field
              component={this.renderCustomInput}
              labelText="Preço por passageiro"
              name="price"
              placeholder="R$ 0,00"
              // renderMask={this.renderCurrencyMask}
              type="text"
              validate={this.requiredValidator}
            />
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

TripDetailsForm.propTypes = {
  travelVehicleSelected: PropTypes.object
};

export default TripDetailsForm;
