import Home from "views/Home/Home";
import Chats from "views/Chat/Chats";
import F2FPayment from "views/F2F/F2FPayment";
import F2FRequest from "views/F2F/F2FRequest";
import F2FRevoke from "views/F2F/F2FRevoke";
import F2FGrant from "./views/F2F/F2FGrant";
import Logout from "views/Logout/Logout";
import ManageTrip from "views/ManageTrip/ManageTrip";
import CloneTrip from "views/CloneTrip/CloneTrip";
import ChangeVehicle from "views/ChangeVehicle/ChangeVehicle";
import Messages from "views/Chat/Messages";
import VehicleEdit from "views/Vehicle/VehicleEdit";
import Vehicles from "views/Vehicle/Vehicles";
import VehicleNew from "views/Vehicle/VehicleNew";
import VehicleRemove from "views/Vehicle/VehicleRemove";
import Reservations from "views/Reservation/Reservations";
import TripList from "views/Trip/Trips";
import TripNew from "views/Trip/TripNew";

import {
  AirportShuttle,
  Chat,
  CardTravel,
  Dashboard,
  ExitToApp,
  CreditCard
} from "@material-ui/icons";

const routes = [
  {
    path: "/home",
    name: "Início",
    icon: Dashboard,
    component: Home,
    layout: "/admin"
  },
  {
    path: "/chats",
    name: "Chat",
    icon: Chat,
    component: Chats,
    layout: "/admin"
  },
  {
    path: "/messages/:id",
    name: "Mensagens",
    component: Messages,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/vehicles",
    name: "Veículo",
    icon: AirportShuttle,
    component: Vehicles,
    layout: "/admin"
  },
  {
    path: "/vehicle-new",
    name: "Novo veículo",
    component: VehicleNew,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/vehicle-edit/:id",
    name: "Editar veículo",
    component: VehicleEdit,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/vehicle-remove/:id",
    component: VehicleRemove,
    name: "Remover",
    layout: "/admin",
    hidden: true
  },
  {
    path: "/trips",
    name: "Viagem",
    icon: CardTravel,
    component: TripList,
    layout: "/admin"
  },
  {
    path: "/face-2-face",
    name: "Pagamento",
    icon: CreditCard,
    component: F2FPayment,
    layout: "/admin"
  },
  {
    path: "/face-2-face-requests",
    name: "Solicitações",
    component: F2FRequest,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/face-2-face-revoke",
    name: "Revogar",
    component: F2FRevoke,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/face-2-face-grant",
    name: "Conceder",
    component: F2FGrant,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/trip-new",
    name: "Nova viagem",
    component: TripNew,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/reservation/passengers/:id",
    name: "Reservas",
    component: Reservations,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/logout",
    name: "Sair",
    icon: ExitToApp,
    component: Logout,
    layout: "/admin"
  },
  {
    path: "/manage-trip/:id",
    name: "Gerenciar",
    component: ManageTrip,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/clone-trip/:id",
    name: "Clonar viagem",
    component: CloneTrip,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/change-vehicle/:id",
    name: "Alterar veículo",
    component: ChangeVehicle,
    layout: "/admin",
    hidden: true
  }
];

export default routes;
