import React from "react";
import { logout } from "services";

/**
 * @return {null}
 */

export default function Logout() {
  React.useEffect(() => {
    logout();
  });

  return null;
}
