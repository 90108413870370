import { rules } from "consts";
import { getUserRoles } from "services";

const check = (rules, action) => {
  const permissions = rules[getUserRoles()];

  if (!permissions) {
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  return false;
};

const Can = props => (check(rules, props.perform) ? props.yes() : props.no());

Can.defaultProps = {
  yes: () => null,
  no: () => null
};

export default Can;
