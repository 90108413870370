import TripActions from "./TripActions";

const INITIAL_STATE = {
  trips: [],
  formConditions: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case TripActions.GET_TRIPS_SUCCESS:
      return { ...state, trips: payload };

    case TripActions.SAVE_TRIP_SUCCESS:
      return { ...state, trip: payload.data };

    case TripActions.CLEAR_TRIP_REQUEST:
      return { ...state, trips: payload };

    default:
      return state;
  }
};
