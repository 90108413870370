export const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const ExternalUrl = Object.freeze({
  CHATS: "/v1/chats",
  COMPANIES: "/v1/companies",
  CONDITIONS: "/v1/conditions",
  F2F_PAYMENT_REQUEST: "/v1/requests/f2f-payment",
  F2F_PAYMENT_REVOKE: "/v1/users/f2f-payment",
  F2F_PAYMENT_GRANT: "/v1/users/f2f-payment/grant",
  SIGN_IN: "/v1/auth/login",
  INFORMAL_RESERVATION: "/v1/reservation/informal",
  INFORMAL_RESERVATION_V2: "/v2/reservation/informal",
  SEND_CHAT_MESSAGE: "/v1/chats/send",
  NOTIFICATION_SUBSCRIBE: `${BACKEND_BASE_URL}/v1/notification/subscribe`,
  TRIPS: "v1/trips",
  VEHICLES: "/v1/vehicles",
  VEHICLES_FOR_COMPANY: id => `/v1/vehicles/company/${id}`,
  UPDATE_TRIP_VEHICLE: tripId => `/v1/trips/${tripId}/update/vehicle`,
  CHAT_SUBSCRIBE: chatCode => `${BACKEND_BASE_URL}/v1/chats/${chatCode}`,
  CHAT_HISTORY: chatCode => `/v1/chats/${chatCode}/history`,
  CITIES_AUTO_COMPLETE: searchText => `/v1/cities/autocomplete/${searchText}`,
  GET_BOARDING_POINTS: tripId => `/v1/places/boarding-points/${tripId}`,
  TRIP_DETAILS: id => `/v1/trips/${id}`,
  CONFIRM_TRIP: id => `/v1/trips/${id}/confirm`,
  CANCEL_TRIP: id => `/v1/trips/${id}`,
  CLONE_TRIP: id => `/v2/trips/${id}/clone`,
  FETCH_TRIPS_COMPANY: (page, pageSize, showPast) =>
    `/v1/trips/company?page=${page}&pageSize=${pageSize}&showPast=${showPast}`,
  HAS_MESSAGE_BEFORE: chatCode => `/v1/chats/${chatCode}/hasMessageBefore`,
  MARK_NOTIFICATION_READ: id => `/v1/notification/read/${id}`,
  PLACES_AUTO_COMPLETE: (searchText, uuid) =>
    `/v1/places/autocomplete/${searchText}?sessionToken=${uuid}`,
  RESERVATION: code => `/v1/reservation/admin/${code}`,
  RESERVATION_V2_REMOVE: id => `/v2/reservation/informal/${id}`,
  RESERVATIONS: id => `/v1/reservation/admin/trip/${id}`,
  RESERVATIONS_V2: id => `/v2/reservation/admin/trip/${id}`,
  RESERVATION_ALLOWANCE_DEADLINE: id =>
    `/v1/trips/${id}/reservation-allowance-deadline`
});

export const InternalUrl = Object.freeze({
  HOME: "/admin/home",
  MANAGE_TRIP: id => `/admin/manage-trip/${id}`,
  CLONE_TRIP: id => `/admin/clone-trip/${id}`,
  CHANGE_VEHICLE: id => `/admin/change-vehicle/${id}`,
  SIGN_IN: "/",
  F2F_REQUEST: "/admin/face-2-face-requests",
  F2F_REVOKE: "/admin/face-2-face-revoke",
  F2F_GRANT: "/admin/face-2-face-grant",
  TRIPS: {
    LIST: "/admin/trips",
    NEW: "/admin/trip-new",
    RESERVATIONS: id => `/admin/reservation/passengers/${id}`
  },
  VEHICLES: {
    EDIT: "/admin/vehicle-edit",
    LIST: "/admin/vehicles",
    NEW: "/admin/vehicle-new",
    REMOVE: "/admin/vehicle-remove",
    VIEW: "/admin/vehicle-view"
  },
  PASSENGER_REMOVE: "/admin/remove/informal-passenger",
  UNKNOWN_ERROR: "/unknown-error",
  CHAT_MESSAGE: id => `/admin/messages/${id}`
});
